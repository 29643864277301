import useQuery from "@Hooks/useQuery";
import OTPComponent from "@Molecule/OTPInput";
import { sendOTP, verifyOTP } from "@Services/officer/recruitment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function OTPConfirmationModal({
  setIsDone,
  handleReset,
  data,
  handleSubmit,
  errorApi = null,
  loading = false,
  hashID = "",
}) {
  const [isSubmitting, setIsSubmiting] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const query = useQuery();
  const hash = query.get("h") || hashID;

  const [OTP, setOTP] = useState(null);
  const [errorOTP, setErrorOTP] = useState(null);
  const [error, setError] = useState(null);
  const [onVerify, setOnVerify] = useState(false);

  const [ableResend, setAbleResend] = useState(false);

  useEffect(() => {
    setOTP(null);
    setErrorOTP(null);
    setError(null);
  }, [hash]);

  useEffect(() => {
    setError(errorApi);
  }, [errorApi]);

  const [time, setTime] = useState("01:00");

  const [strokeDasharray, setStrokeDasharray] = useState("283 283");

  function formatTimeLeft(timeLeft) {
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  const TIME_LIMIT = 60;

  let timePassed = 0;
  let timeLeft = TIME_LIMIT;

  //eslint-disable-next-line no-unused-vars
  let timerInterval = null;
  function startTimer() {
    setAbleResend(false);
    timerInterval = setInterval(() => {
      timePassed = timePassed += 1;
      timeLeft = TIME_LIMIT - timePassed;

      // The time left label is updated
      if (timeLeft >= 0) {
        setTime(formatTimeLeft(timeLeft));

        setCircleDasharray();
      }
    }, 1000);
  }

  function calculateTimeFraction() {
    return timeLeft / TIME_LIMIT;
  }

  // Update the dasharray value as time passes, starting with 283
  function setCircleDasharray() {
    const circleDasharray = `${(calculateTimeFraction() * 283).toFixed(0)} 283`;

    setStrokeDasharray(circleDasharray);

    if (circleDasharray.split(" ")[0] === "0") {
      setAbleResend(true);
    }
  }

  useEffect(() => {
    if (hash) {
      startTimer();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const COLOR_CODES = {
    info: {
      color: "green",
    },
  };

  let remainingPathColor = COLOR_CODES.info.color;

  const handleResend = async () => {
    try {
      setIsSubmiting(true);

      const { response } = await sendOTP(
        data?.phoneNumber?.startsWith("62")
          ? data?.phoneNumber
          : "62" + data?.phoneNumber
      );

      navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });

      setIsSubmiting(false);
    } catch (err) {
      const errorMessage = err?.response?.data?.error || "something went wrong";
      setIsSubmiting(false);
      if (errorMessage?.toLowerCase()?.includes("fill all field")) {
        setError("Cek kembali form dan pastikan semua data telah terisi");
      } else {
        setError(errorMessage);
      }
    }
  };

  // function DataURIToBlob(dataURI) {
  //   const splitDataURI = dataURI.split(',')
  //   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  //   const ia = new Uint8Array(byteString.length)
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i)

  //   return new Blob([ia], { type: mimeString })
  // }

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      setOnVerify(true);
      await verifyOTP(hash, OTP);

      handleSubmit();
      // setIsDone(true);
    } catch (err) {
      if (err?.response?.data?.error?.toLowerCase()?.includes("wrong code")) {
        setError("Wrong OTP input");
      } else {
        setError(err?.response?.data?.error);
      }
    }
    setOnVerify(false);
  };

  return (
    <div className={Styles.container}>
      {/* <div className={Styles.nav}>
        <button onClick={() => navigate(`${pathname}?h=${hash}&v=false`)}>
          <Icon icon={"arrow-left-back"} size={24} />
          <span>Ubah Nomor Whatsapp</span>
        </button>
        <button>
          <Icon icon={'cross'} size={24} />
        </button>
      </div> */}
      <div className={Styles.header}>
        <h3>Konfirmasi OTP</h3>
        <p>
          {`Masukan kode OTP yang kami kirim ke nomor Whatsapp +62 8`}
          {data?.phoneNumber?.length &&
            [
              ...Array(
                (data?.phoneNumber?.length > 3
                  ? data?.phoneNumber?.length
                  : 3) - 3
              ),
            ]?.map((e, i) => {
              return (
                <span className="busterCards" key={i}>
                  *
                </span>
              );
            })}
          {data?.phoneNumber?.slice(
            data?.phoneNumber?.length - 2,
            data?.phoneNumber?.length
          )}
        </p>
      </div>
      <div className={Styles.content}>
        <form onSubmit={handleConfirm}>
          <OTPComponent
            onChange={(value) => {
              setOTP(value || "");
              setErrorOTP(null);
              setError(null);
            }}
            alert={errorOTP}
          />
          <span
            className={errorOTP || error ? Styles.alertText : Styles.noAlert}
          >
            {errorOTP || error || "no alert"}
          </span>

          <div className={Styles.resendWrapper}>
            <div className={Styles.resendController}>
              <button
                type="button"
                disabled={!ableResend}
                onClick={handleResend}
                className={
                  ableResend ? Styles.ableResend : Styles.disableResend
                }
              >
                {onVerify ? "Requesting OTP" : "Request again?"}
              </button>
            </div>
            <div className={Styles.timerWrapper}>
              <div className={Styles.baseTimer}>
                <svg
                  className={Styles.SVG}
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g className={Styles.circle}>
                    <circle
                      className={Styles.pathElapsed}
                      cx="50"
                      cy="50"
                      r="45"
                    />
                    <path
                      id="base-timer-path-remaining"
                      strokeDasharray={strokeDasharray}
                      className={`${Styles.pathRemaining} ${remainingPathColor}`}
                      d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
                    ></path>
                  </g>
                </svg>
              </div>
              <span>{time}</span>
            </div>
          </div>
          <div className={Styles.buttonWrapper}>
            <button
              type="submit"
              disabled={
                !OTP || OTP?.length < 4 || onVerify || isSubmitting || loading
              }
            >
              {onVerify && loading
                ? "Mengonfirmasi..."
                : "Konfirmasi dan Kirim"}
            </button>
          </div>
          {/* <DownloadOption /> */}
        </form>
      </div>
    </div>
  );
}
