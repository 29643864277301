import { API } from "@Config/api";
import { imageToPdf } from "@Helpers/convertImageToPdf";
import moment from "moment";

export function getContractApproval(hash) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/contract/${hash}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function setContractApproval(id, tag, remark) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/business-support/approval-contract/${id}`,
        {
          tag,
          remark,
        },
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export async function signContract(id, data) {
  const form = new FormData();

  let signature = await fetch(data.signature)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], "sign.png", { type: "image/png" });
    });

  let initials = await fetch(data.initials)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], "initials.png", { type: "image/png" });
    });

  form.append("latitude", data.latitudeSubmit);
  form.append("longitude", data.longitudeSubmit);
  form.append("address", data.addressSubmit);
  form.append("identityCardLatitude", data.latitudeImageIdCard);
  form.append("identityCardLongitude", data.longitudeImageIdCard);
  form.append("identityCardAddress", data.addressImageIdCard);
  form.append("imageLongitude", data.longitudeImage);
  form.append("imageLatitude", data.latitudeImage);
  form.append("imageAddress", data.addressImage);
  form.append("files", signature);
  form.append("files", initials);
  form.append("files", data.image);
  form.append("files", data.imagesIdCard);
  form.append("type[0]", "signature");
  form.append("type[1]", "initials");
  form.append("type[2]", "image");
  form.append("type[3]", "identityCard");

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/business-support/sign-contract/${id}`,
        form,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export async function signContractExtend(id, data) {
  const form = new FormData();

  let signature = await fetch(data.signature)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], "sign.png", { type: "image/png" });
    });

  let initials = await fetch(data.initials)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], "initials.png", { type: "image/png" });
    });

  form.append("latitude", data.latitudeSubmit);
  form.append("longitude", data.longitudeSubmit);
  form.append("address", data.addressSubmit);
  form.append("identityCardLatitude", data.latitudeImageIdCard);
  form.append("identityCardLongitude", data.longitudeImageIdCard);
  form.append("identityCardAddress", data.addressImageIdCard);
  form.append("imageLongitude", data.longitudeImage);
  form.append("imageLatitude", data.latitudeImage);
  form.append("imageAddress", data.addressImage);
  form?.append("personalData[candidateName]", data?.dataStepOne?.name || "");
  form?.append(
    "personalData[placeOfBirth]",
    data?.dataStepOne?.birthPlace || ""
  );
  form?.append(
    "personalData[dateOfBirth]",
    data?.dataStepOne?.birthDateTemp
      ? moment(new Date(data?.dataStepOne?.birthDateTemp))?.format("YYYY-MM-DD")
      : ""
  );
  form?.append("personalData[gender]", data?.dataStepOne?.gender || "");
  form?.append("personalData[religion]", data?.dataStepOne?.religion || "");
  form?.append("personalData[bloodType]", data?.dataStepOne?.bloodType || "");
  form?.append("personalData[bodyHeight]", data?.dataStepOne?.height || "");
  form?.append("personalData[bodyWeight]", data?.dataStepOne?.weight || "");
  // form?.append("personalData[positionID]", data?.positionID || "");
  // form?.append("personalData[recruiterID]", data?.recruiterID || "20230923");
  // form?.append("personalData[jobPostingID]", data?.jobPostingID || "");
  form?.append(
    "personalData[maritalStatus]",
    data?.dataStepOne?.maritalStatus || ""
  );
  form?.append("personalData[familyId]", data?.dataStepOne?.KK || "");
  form?.append("personalData[personId]", data?.dataStepOne?.NIK || "");
  // form?.append("personalData[personId]", data?.dataStepOne?.NIK || "");
  form?.append(
    "personalData[personIdPeriod]",
    !data?.dataStepOne?.lifetimeIdCard
      ? data?.dataStepOne?.idCardPeriodTemp
        ? moment(new Date(data?.dataStepOne?.idCardPeriodTemp)).format(
            "YYYY-MM-DD"
          )
        : ""
      : "SEUMUR HIDUP"
  );

  form?.append(
    "personalData[taxId]",
    !data?.dataStepOne?.noTaxNumber
      ? data?.dataStepOne?.taxNumber
        ? data?.dataStepOne?.taxNumber
        : ""
      : "TIDAK MEMILIKI NPWP"
  );

  form?.append("personalData[email]", data?.dataStepOne?.email || "");
  form?.append(
    "personalData[phoneNumber]",
    "62" + data?.dataStepOne?.phoneNumber || ""
  );

  form?.append(
    "personalData[motherName]",
    data?.dataStepOne?.biologicalMotherName || ""
  );

  form?.append(
    "personalData[address][ktp][address]",
    data?.dataStepOne?.idCardAddress || ""
  );

  form?.append(
    "personalData[address][ktp][neighborhood]",
    `${data?.dataStepOne?.idCardRt}/${data?.dataStepOne?.idCardRw}` || ""
  );

  form?.append(
    "personalData[address][ktp][subDistrict]",
    data?.dataStepOne?.idCardWard || ""
  );

  form?.append(
    "personalData[address][ktp][district]",
    data?.dataStepOne?.idCardSubdistrict || ""
  );

  form?.append(
    "personalData[address][ktp][city]",
    data?.dataStepOne?.idCardRegencyTemp || ""
  );

  form?.append(
    "personalData[address][ktp][province]",
    data?.dataStepOne?.idCardProvinceTemp || ""
  );

  form?.append(
    "personalData[address][ktp][postal]",
    data?.dataStepOne?.idCardPostal || ""
  );

  if (data?.dataStepOne?.isDomicileTheSame) {
    form?.append(
      "personalData[address][domicile][domicileAddress]",
      data?.dataStepOne?.idCardAddress || ""
    );

    form?.append(
      "personalData[address][domicile][domicileNeighbourhood]",
      `${data?.dataStepOne?.idCardRt}/${data?.dataStepOne?.idCardRw}` || ""
    );

    form?.append(
      "personalData[address][domicile][domicileSubDistrict]",
      data?.dataStepOne?.idCardWard || ""
    );

    form?.append(
      "personalData[address][domicile][domicileDistrict]",
      data?.dataStepOne?.idCardSubdistrict || ""
    );

    form?.append(
      "personalData[address][domicile][domicileCity]",
      data?.dataStepOne?.idCardRegencyTemp || ""
    );

    form?.append(
      "personalData[address][domicile][domicileProvince]",
      data?.dataStepOne?.idCardProvinceTemp || ""
    );

    form?.append(
      "personalData[address][domicile][domicilePostal]",
      data?.dataStepOne?.idCardPostal || ""
    );
  } else {
    form?.append(
      "personalData[address][domicile][domicileAddress]",
      data?.dataStepOne?.domicileAddress || ""
    );

    form?.append(
      "personalData[address][domicile][domicileNeighbourhood]",
      `${data?.dataStepOne?.domicileRt}/${data?.dataStepOne?.domicileRw}` || ""
    );

    form?.append(
      "personalData[address][domicile][domicileSubDistrict]",
      data?.dataStepOne?.domicileWard || ""
    );

    form?.append(
      "personalData[address][domicile][domicileDistrict]",
      data?.dataStepOne?.domicileSubdistrict || ""
    );

    form?.append(
      "personalData[address][domicile][domicileCity]",
      data?.dataStepOne?.domicileRegencyTemp || ""
    );

    form?.append(
      "personalData[address][domicile][domicileProvince]",
      data?.dataStepOne?.domicileProvinceTemp || ""
    );

    form?.append(
      "personalData[address][domicile][domicilePostal]",
      data?.dataStepOne?.domicilePostal || ""
    );
  }

  form?.append(
    "personalData[lastEducation][type]",
    data?.dataStepOne?.educationTemp || ""
  );

  form?.append(
    "personalData[lastEducation][name]",
    data?.dataStepOne?.institution || ""
  );

  form?.append(
    "personalData[lastEducation][faculty]",
    data?.dataStepOne?.faculty || ""
  );

  form?.append(
    "personalData[lastEducation][grade]",
    data?.dataStepOne?.gpa || ""
  );

  form?.append(
    "personalData[contact][emergencyContact][emergencyContactName]",
    data?.dataStepOne?.emergencyContactName || ""
  );

  form?.append(
    "personalData[contact][emergencyContact][emergencyContactRelation]",
    data?.dataStepOne?.emergencyContactRelationshipTemp || ""
  );

  form?.append(
    "personalData[contact][emergencyContact][emergencyContactNumber]",
    "62" + data?.dataStepOne?.emergencyPhoneNumber || ""
  );

  form?.append(
    "personalData[contact][emergencyContact][emergencyContactAddress]",
    data?.dataStepOne?.emergencyAddress || ""
  );

  form?.append(
    "personalData[contact][family1][family1Name]",
    data?.dataStepOne?.sameHouseFamilyContactName || ""
  );
  form?.append(
    "personalData[contact][family1][family1Relation]",
    data?.dataStepOne?.sameHouseFamilyContactRelationshipTemp || ""
  );

  form?.append(
    "personalData[contact][family1][family1PhoneNumber]",
    "62" + data?.dataStepOne?.sameHouseFamilyContactPhoneNumber || ""
  );

  form?.append(
    "personalData[contact][family1][family1Address]",
    data?.dataStepOne?.sameHouseFamilyContactAddress || ""
  );

  form?.append(
    "personalData[contact][family2][family2Name]",
    data?.dataStepOne?.differentHouseFamilyContactName || ""
  );

  form?.append(
    "personalData[contact][family2][family2Relation]",
    data?.dataStepOne?.differentHouseFamilyContactRelationshipTemp || ""
  );

  form?.append(
    "personalData[contact][family2][family2PhoneNumber]",
    "62" + data?.dataStepOne?.differentHouseFamilyContactPhoneNumber || ""
  );

  form?.append(
    "personalData[contact][family2][family2Address]",
    data?.dataStepOne?.differentHouseFamilyContactAddress || ""
  );

  form?.append(
    "personalData[others][bank][BankName]",
    data?.dataStepOne?.bankNameTemp || ""
  );

  form?.append(
    "personalData[others][bank][BankNumber]",
    data?.dataStepOne?.bankAccountNumber || ""
  );

  form?.append(
    "personalData[others][nearestClinic]",
    data?.dataStepOne?.nearestHealthFacility || ""
  );

  if (data?.dataStepOne?.fbUsername) {
    form?.append(
      "personalData[others][socialMedia][facebook]",
      data?.dataStepOne?.fbUsername || ""
    );
  }
  if (data?.dataStepOne?.igUsername) {
    form?.append(
      "personalData[others][socialMedia][instagram]",
      data?.dataStepOne?.igUsername || ""
    );
  }

  if (data?.dataStepOne?.twitterUsername) {
    form?.append(
      "personalData[others][socialMedia][twitter]",
      data?.dataStepOne?.twitterUsername || ""
    );
  }

  if (data?.dataStepOne?.linkedinUsername) {
    form?.append(
      "personalData[others][socialMedia][linkedin]",
      data?.dataStepOne?.linkedinUsername || ""
    );
  }

  if (
    !data?.dataStepOne?.fbUsername &&
    !data?.dataStepOne?.igUsername &&
    !data?.dataStepOne?.twitterUsername &&
    !data?.dataStepOne?.linkedinUsername
  ) {
    form?.append("personalData[others][socialMedia]", {});
  }

  const familyMembers = data?.familyMembers || [];

  for (let i = 0; i < familyMembers?.length; i++) {
    const member = familyMembers[i];
    form?.append(`familyData[${i}][name]`, member?.name);
    form?.append(`familyData[${i}][relation]`, member?.relationship || "-");
    form?.append(`familyData[${i}][placeOfBirth]`, member?.birthPlace);
    form?.append(
      `familyData[${i}][dateOfBirth]`,
      member?.birthDate
        ? moment(new Date(member?.birthDate)).format("YYYY-MM-DD")
        : ""
    );
    form?.append(`familyData[${i}][nik]`, member?.nik);
    form?.append(`familyData[${i}][gender]`, member?.gender);
  }

  const educationHistory = data?.educationHistory || [];

  for (let i = 0; i < educationHistory?.length; i++) {
    const education = educationHistory[i];
    form?.append(`educationData[${i}][startYear]`, education?.startYear);
    form?.append(`educationData[${i}][endYear]`, education?.endYear);
    form?.append(`educationData[${i}][type]`, education?.type);
    form?.append(`educationData[${i}][name]`, education?.name);
    form?.append(`educationData[${i}][mastery]`, education?.mastery);
    form?.append(
      `educationData[${i}][sertificateNumber]`,
      education?.sertificateNumber
    );
    form?.append(`educationData[${i}][city]`, education?.city);
  }

  const jobHistory = data?.jobHistory || [];

  for (let i = 0; i < jobHistory?.length; i++) {
    const job = jobHistory[i];
    form?.append(`workExperienceData[${i}][startYear]`, job?.startYear);
    form?.append(`workExperienceData[${i}][endYear]`, job?.endYear);
    form?.append(`workExperienceData[${i}][companyName]`, job?.companyName);
    form?.append(`workExperienceData[${i}][position]`, job?.position);
    form?.append(`workExperienceData[${i}][remark]`, job?.remark);
  }

  if (!jobHistory?.length) {
    form?.append(`workExperienceData`, []);
  }

  const performanceHistory = data?.performanceHistory || [];

  for (let i = 0; i < performanceHistory?.length; i++) {
    const performance = performanceHistory[i];
    form?.append(`achievementData[${i}][year]`, performance?.year);
    form?.append(
      `achievementData[${i}][achievement]`,
      performance?.achievement
    );
    form?.append(`achievementData[${i}][remark]`, performance?.remark);
  }

  if (!performanceHistory?.length) {
    form?.append(`achievementData`, []);
  }

  form.append("files", signature);
  form.append("types[0]", "signature");
  form.append("files", initials);
  form.append("types[1]", "initials");
  form.append("files", data.image);
  form.append("types[2]", "image");
  form.append("files", data.imagesIdCard);
  form.append("types[3]", "identityCard");

  const files = data?.documents?.mandatory?.filter((doc) => doc?.file);
  const files2 = data?.documents?.nonMandatory?.filter((doc) => doc?.file);
  const files3 = data?.documents?.additional?.filter((doc) => doc?.file);

  const startIndex = 4;
  const nextIndex = startIndex + files?.length;
  const additionalIndex = nextIndex + files2?.length;

  for (let i = 0; i < files?.length; i++) {
    const file = files[i];

    if (file?.title?.toLowerCase() !== "cv") {
      form?.append("files", file?.file);
      form?.append(`types[${startIndex + i}]`, file?.title?.toUpperCase());
    } else {
      const fileExtension = file?.file?.type?.split("/").pop().toLowerCase();

      let validPdf = file?.file;
      if (
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "png"
      ) {
        validPdf = await imageToPdf(file?.file);
      }
      form?.append("files", validPdf);
      form?.append(`types[${startIndex + i}]`, file?.title?.toUpperCase());
    }
  }

  for (let i = 0; i < files2?.length; i++) {
    const file = files2[i];
    form?.append("files", file?.file);
    form?.append(`types[${nextIndex + i}]`, file?.title?.toUpperCase());
  }

  for (let i = 0; i < files3?.length; i++) {
    const file = files3[i];
    form?.append("files", file?.file);
    form?.append(`types[${additionalIndex + i}]`, file?.title?.toUpperCase());
  }

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/business-support/sign-extend-contract/${id}`,
        form,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
