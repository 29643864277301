import Styles from "./style.module.scss";
import ICON365 from "../../../assets/Images/kerja365-logo-bs.png";
import ICON365ALT from "../../../assets/Images//kerja365-logo-bs2.png";
import WA_ICON from "../../../assets/Images/whatsapp-logo.png";
import { useState, useEffect, useMemo } from "react";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import BSProfilePopup from "@Molecule/_popup/BSProfilePopup";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";

const HCNavbar = ({ children, ...props }) => {
  const currentMenu = sessionStorage.getItem("selectedMenu");
  const [onHover, setOnHover] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(+currentMenu || 1);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const username = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  // const [submenu, setSubmenu] = useState(false);
  // const username = sessionStorage.getItem("username");
  // const role = sessionStorage.getItem("role");

  const profilePictureSource = localStorage.getItem("profilePicture");

  const profilePicture = useMemo(() => {
    if (profilePictureSource && profilePictureSource !== "null") {
      if (profilePictureSource?.includes("http")) {
        return profilePictureSource?.replace(
          "https://myworkspace",
          "https://api1.myworkspace"
        );
      } else {
        return fileBaseUrl + profilePictureSource;
      }
    } else {
      return Images.AVA_DEFAULT;
    }
  }, [profilePictureSource]);

  const [showDropdown, setShowDropdown] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  // const handleBack = () => {
  //   if (pathname?.includes("/candidates/")) {
  //     navigate("/candidates", { replace: true });
  //   }
  // };

  const activeMenu = useMemo(() => {
    if (pathname === "/") {
      return 1;
    } else if (pathname?.toLowerCase()?.includes("drafts-contract")) {
      return 2;
    } else if (pathname?.toLowerCase()?.includes("flb-verification")) {
      return 3;
    } else if (pathname?.toLowerCase()?.includes("template-kontrak-c2c")) {
      return 4;
    } else if (pathname?.toLowerCase()?.includes("template-kontrak-k365")) {
      return 5;
    }
  }, [pathname]);

  useEffect(() => {
    sessionStorage.setItem("selectedMenu", activeMenu);
    setSelectedMenu(activeMenu);
  }, [activeMenu]);

  const menuTemplate = useMemo(() => {
    return [
      {
        id: 1,
        title: "User",
      },
      {
        id: 2,
        title: "Kontrak",
      },
      {
        id: 3,
        title: "Verifikasi FLB",
      },
      {
        id: 4,
        title: "Template Kontrak",
      },
      {
        id: 5,
        title: "Template Kontrak",
      },
    ];
  }, []);

  // const isNavigated = useMemo(() => {
  //   switch (pathname) {
  //     case "/":
  //       return false;
  //     case "/drafts-contract":
  //       return false;
  //     default:
  //       return true;
  //   }
  // }, [pathname]);

  // useEffect(() => {
  //   if (!onHover) {
  //     setSubmenu(false);
  //   }
  //   if (!burgerOpen) {
  //     setSubmenu(false);
  //   }
  // }, [onHover, burgerOpen]);

  return (
    <div className={Styles.container}>
      <div
        className={Styles.sidebar}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <div className={Styles.top}>
          {onHover ? (
            <div className={Styles.iconWrapper}>
              <img src={ICON365ALT} alt="" height={"34px"} />
            </div>
          ) : (
            <div className={Styles.iconWrapper}>
              <img src={ICON365} alt="" height={"34px"} />
            </div>
          )}
          <div className={Styles.navbar}>
            <div
              className={
                selectedMenu !== 1
                  ? Styles.menuWrapper
                  : Styles.selectedMenuWrapper
              }
              onClick={() => {
                setSelectedMenu(1);
                navigate("/");
              }}
            >
              <div className={Styles.button}>
                <Icon icon="user-circle" size="20px" className={Styles.icon} />
                {onHover && <div className={Styles.text}>User</div>}
              </div>
            </div>
            <div
              className={
                selectedMenu !== 2
                  ? Styles.menuWrapper
                  : Styles.selectedMenuWrapper
              }
              onClick={() => {
                setSelectedMenu(2);
                navigate("drafts-contract");
              }}
            >
              <div className={Styles.button}>
                <Icon
                  icon="document-text"
                  size="20px"
                  className={Styles.icon}
                />
                {onHover && <div className={Styles.text}>Kontrak</div>}
              </div>
            </div>
            <div
              className={
                selectedMenu !== 3
                  ? Styles.menuWrapper
                  : Styles.selectedMenuWrapper
              }
              onClick={() => {
                setSelectedMenu(3);
                navigate("flb-verification");
              }}
            >
              <div className={Styles.button}>
                <Icon
                  icon="building-office-2"
                  size="20px"
                  className={Styles.icon}
                />
                {onHover && <div className={Styles.text}>Verifikasi FLB</div>}
              </div>
            </div>

            {/* <div
              className={
                (selectedMenu === 4 && !onHover) ||
                (selectedMenu === 5 && !onHover)
                  ? Styles.selectedMenuWrapper
                  : Styles.menuWrapper
              }
              onClick={() => {
                setSubmenu(!submenu);
              }}
            >
              <div className={Styles.button}>
                <Icon icon="file" size="20px" className={Styles.icon} />
                {onHover && <div className={Styles.text}>Template Kontrak</div>}
              </div>
              {onHover && (
                <Icon
                  icon={submenu ? "arrow-up" : "arrow-down"}
                  size="20"
                  className={Styles.arrow}
                />
              )}
            </div> */}

            {/* {submenu && onHover && (
              <div className={Styles.submenuWrapper}>
                <div className={Styles.submenu}>
                  <div className={Styles.curveWrapper}>
                    <div className={Styles.curve}></div>
                    <div className={Styles.straight}></div>
                  </div>
                  <div className={Styles.button}>
                    <div
                      className={
                        selectedMenu !== 4
                          ? Styles.menuWrapper
                          : Styles.selectedMenuWrapper
                      }
                      onClick={() => {
                        setSelectedMenu(4);
                        navigate("template-kontrak-c2c");
                      }}
                    >
                      <div className={Styles.text}>Kontrak C2C</div>
                    </div>
                  </div>
                </div>
                <div className={Styles.submenu}>
                  <div className={Styles.curveWrapper}>
                    <div className={Styles.curve}></div>
                    <div
                      className={Styles.straight}
                      style={{ height: 5 }}
                    ></div>
                  </div>
                  <div className={Styles.button}>
                    <div
                      className={
                        selectedMenu !== 5
                          ? Styles.menuWrapper
                          : Styles.selectedMenuWrapper
                      }
                      onClick={() => {
                        setSelectedMenu(5);
                        navigate("template-kontrak-k365");
                      }}
                    >
                      <div className={Styles.text}>Kontrak Kerja365</div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className={Styles.bottom}>
          <div className={Styles.helpdesk}>
            <div className={Styles.icon}>
              <img src={WA_ICON} height={21.33} width={21.33} alt="" />
            </div>
            {onHover && (
              <div className={Styles.helpdeskButtonWrapper}>
                <div className={Styles.information}>
                  <div className={Styles.title}>Help Desk</div>
                  <div className={Styles.text}>
                    Butuh bantuan? kami siap membantu anda
                  </div>
                </div>
                <div className={Styles.pressable}>
                  <div className={Styles.label}>Mulai Chat</div>
                  <Icon
                    icon="arrow-right-stick"
                    size="20"
                    className={Styles.arrow}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.header}>
          <div className={Styles.routeNameWrapper}>
            <div className={Styles.routeNameTitle}>
              {menuTemplate?.find((obj) => obj?.id === selectedMenu)?.title}
            </div>
            {pathname?.includes("/flb-verification/") && (
              <div className={Styles.navigationTextWrapper}>
                <div onClick={() => navigate(-1)} className={Styles.linkText}>
                  {menuTemplate?.find((obj) => obj?.id === selectedMenu)?.title}
                </div>
                <Icon
                  icon="arrow-right"
                  size="20"
                  className={Styles.arrowIcon}
                />
                <div className={Styles.text}>Detail FLB</div>
              </div>
            )}
            {/* {isNavigated && (
              <div className={Styles.navigationTextWrapper}>
                <div onClick={handleBack} className={Styles.linkText}>
                  {menuTemplate?.find((obj) => obj?.id === selectedMenu)?.title}
                </div>
                <Icon
                  icon="arrow-right"
                  size="20"
                  className={Styles.arrowIcon}
                />
                <div className={Styles.text}>
                  {pathname?.includes("/candidates/") ? "Verifikasi Data" : ""}
                </div>
              </div>
            )} */}
          </div>
          <div
            className={Styles.iconWrapper}
            onClick={() => setBurgerOpen(!burgerOpen)}
          >
            <Icon
              icon={burgerOpen ? "cross" : "bars-3-bottom-left"}
              size="24"
              color="#000"
            />
          </div>
          <div
            className={Styles.profileWrapper}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className={Styles.profilePicture}>
              <img src={profilePicture} alt="" />
            </div>
            <div className={Styles.userInformation}>
              <div className={Styles.username}>{username}</div>
              <div className={Styles.jobTitle}>{role}</div>
            </div>
            <div className={Styles.iconWrapper}>
              <Icon
                icon={showDropdown ? "arrow-up" : "arrow-down"}
                size="20"
                className={Styles.icon}
              />
            </div>
            {showDropdown && (
              <div className={Styles.dropdownWrapper}>
                <BSProfilePopup
                  profilePicture={profilePicture}
                  username={username}
                  role={role}
                />
              </div>
            )}
          </div>
        </div>
        <div className={Styles.body}>{children}</div>
        <div
          className={
            burgerOpen
              ? Styles.menuModalWrapper
              : Styles.inactiveMenuModalWrapper
          }
        >
          <div className={Styles.menuModal}>
            <>
              <div
                className={Styles.navigation}
                onClick={() => {
                  setSelectedMenu(1);
                  navigate("/");
                  setBurgerOpen(false);
                }}
              >
                <div
                  className={
                    selectedMenu === 1 ? Styles.selectedButton : Styles.button
                  }
                >
                  <Icon icon="user-circle" size="20" className={Styles.icon} />
                  <div className={Styles.text}>User</div>
                </div>
              </div>
              <div
                className={Styles.navigation}
                onClick={() => {
                  setSelectedMenu(2);
                  navigate("drafts-contract");
                  setBurgerOpen(false);
                }}
              >
                <div
                  className={
                    selectedMenu === 2 ? Styles.selectedButton : Styles.button
                  }
                >
                  <Icon
                    icon="document-text"
                    size="20"
                    className={Styles.icon}
                  />
                  <div className={Styles.text}>Kontrak</div>
                </div>
              </div>
              <div
                className={Styles.navigation}
                onClick={() => {
                  setSelectedMenu(3);
                  navigate("flb-verification");
                  setBurgerOpen(false);
                }}
              >
                <div
                  className={
                    selectedMenu === 3 ? Styles.selectedButton : Styles.button
                  }
                >
                  <Icon
                    icon="building-office-2"
                    size="20"
                    className={Styles.icon}
                  />
                  <div className={Styles.text}>Verifikasi FLB</div>
                </div>
              </div>

              {/* <div className={Styles.navigation}>
                <div
                  className={
                    (selectedMenu === 4 && !onHover) ||
                    (selectedMenu === 5 && !onHover)
                      ? Styles.selectedButton
                      : Styles.button
                  }
                  onClick={() => setSubmenu(!submenu)}
                >
                  <Icon icon="file" size="20" className={Styles.icon} />
                  <div className={Styles.text}>Template Kontrak</div>
                </div>

                <Icon
                  icon={submenu ? "arrow-up" : "arrow-down"}
                  size="20"
                  className={Styles.arrow}
                />
              </div> */}
              {/* {submenu && (
                <div className={Styles.submenuWrapper}>
                  <div className={Styles.submenu}>
                    <div className={Styles.curveWrapper}>
                      <div className={Styles.curve}></div>
                      <div className={Styles.straight}></div>
                    </div>
                    <div className={Styles.button}>
                      <div
                        className={
                          selectedMenu !== 4
                            ? Styles.menuWrapper
                            : Styles.selectedMenuWrapper
                        }
                        onClick={() => {
                          setSelectedMenu(4);
                          navigate("template-kontrak-c2c");
                          setBurgerOpen(false);
                        }}
                      >
                        <div className={Styles.text}>Kontrak C2C</div>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.submenu}>
                    <div className={Styles.curveWrapper}>
                      <div className={Styles.curve}></div>
                      <div
                        className={Styles.straight}
                        style={{ height: 5 }}
                      ></div>
                    </div>
                    <div className={Styles.button}>
                      <div
                        className={
                          selectedMenu !== 5
                            ? Styles.menuWrapper
                            : Styles.selectedMenuWrapper
                        }
                        onClick={() => {
                          setSelectedMenu(5);
                          navigate("template-kontrak-k365");
                          setBurgerOpen(false);
                        }}
                      >
                        <div className={Styles.text}>Kontrak Kerja365</div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HCNavbar;
