import Styles from "./style.module.scss";
import ICON from "../../../../../assets/Images/approval.png";

const SentContractExtendContractToBmModal = ({ onClickBack = () => {} }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.imageWrapper}>
          <img src={ICON} alt="" height={100} width={100} />
        </div>
      </div>
      <div className={Styles.iconWrapper}>
        <div className={Styles.title}>Request Terkirim</div>
        <div className={Styles.text}>Request BM approval telah berhasil dikirimkan</div>
      </div>
      <div className={Styles.buttons}>
        <div className={Styles.secondaryButton} onClick={onClickBack}>
          <div className={Styles.text}>Kembali</div>
        </div>
      </div>
    </div>
  );
};

export default SentContractExtendContractToBmModal;
