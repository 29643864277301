import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./styles.module.scss";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import Stepper from "@Molecule/Stepper";
import FirstStep from "./FirstStep";
import LoadingAnimation from "@Atom/LoadingAnimation";
import SecondStep from "@Organism/Additional/CandidateForm/OnProgress/Second";
import ThirdStep from "@Organism/Additional/CandidateForm/OnProgress/Third";
import FourthStep from "@Organism/Additional/CandidateForm/OnProgress/Fourth";
import FifthStep from "@Organism/Additional/CandidateForm/OnProgress/Fifth";
import SixthStep from "@Organism/Additional/CandidateForm/OnProgress/Sixth";
import SignContract from "../SignContract";

export default function OnProgress({
  currentStep,
  setCurrentStep,
  setStep,
  isLoadingDataSync,
  syncData,
  dataStepOne,
  setDataStepone,
  disableInput,
  isPhoneVerified,
  setIsPhoneVerified,
  loadingPhoneVerify,
  onClickPhoneVerification,
  disablePhoneVerification,
  familyMembers,
  setFamilyMembers,
  educationHistory,
  setEducationHistory,
  jobHistory,
  setJobHistory,
  performanceHistory,
  setPerformanceHistory,
  setDocuments,
  documents,
  dataToSend,
  setDataToSend,
  isDoneChecker,
  isLoadingCandidateExtendSync,
}) {
  const [stepperHeight, setStepperHeight] = useState();
  const stepperRef = useRef();
  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const options = useMemo(() => {
    return {
      banks: syncData?.banks,
      cities: syncData?.cities,
      provinces: syncData?.provinces,
      genders: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Gender")
        ?.map((el) => el?.value),
      religions: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Religion")
        ?.map((el) => el?.value),
      bloodTypes: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Blood Type")
        ?.map((el) => el?.value),
      maritalStatus: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Marital Status")
        ?.map((el) => el?.value),
      educations: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Education")
        ?.map((el) => el?.value),
      relationships: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Family Relationship")
        ?.map((el) => el?.value),
    };
  }, [syncData]);

  const getHeight = () => {
    const newHeight = stepperRef?.current?.clientHeight;
    setStepperHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const disableNext = useMemo(() => {
    switch (+currentStep) {
      case 1:
        return !isDoneChecker?.first();
      case 2:
        return !isDoneChecker?.second();
      case 3:
        return !isDoneChecker?.third();
      case 4:
        return !isDoneChecker?.fourth();
      case 5:
        return !isDoneChecker?.fifth();
      case 6:
        return !isDoneChecker?.sixth();
      case 7:
        return !isDoneChecker?.seventh();
      default:
        return !isDoneChecker?.first();
    }
  }, [isDoneChecker, currentStep]);

  const stepsOptions = useMemo(() => {
    return [
      {
        id: 1,
        label: "Personal Data",
        isDone: isDoneChecker?.first(),
      },
      {
        id: 2,
        label: "Data Keluarga",
        isDone: isDoneChecker?.second(),
      },
      {
        id: 3,
        label: "Data Pendidikan",
        isDone: isDoneChecker?.third(),
      },
      {
        id: 4,
        label: "Data Pekerjaan",
        isDone: isDoneChecker?.fourth(),
      },
      {
        id: 5,
        label: "Data Prestasi",
        isDone: isDoneChecker?.fifth(),
      },
      {
        id: 6,
        label: "Unggah Dokumen",
        isDone: isDoneChecker?.sixth(),
      },
      {
        id: 7,
        label: "TTD Kontrak",
        isDone: isDoneChecker?.seventh(),
      },
    ];
  }, [isDoneChecker]);

  // const handleClickStep = (step) => {
  //   if (+step === 1 || +currentStep > +step) {
  //     setCurrentStep(step);
  //   } else if (+currentStep < +step) {
  //     if (stepsOptions[+currentStep - 1]?.isDone) {
  //       setCurrentStep(step);
  //     }
  //   }
  // };

  const handleClickStep = (step) => {
    if (currentStep >= step) {
      setCurrentStep(step);
      // setAlertMessage("");
    }
  };
  const [errorField, setErrorField] = useState(false);

  const onPrevious = () => {
    setErrorField(false);
    currentStep > 1 ? setCurrentStep(currentStep - 1) : setStep(2);
  };

  const onNext = () => {
    let checkField = disableNext;
    if (checkField) {
      setErrorField(checkField);
      scrollingTop();
    } else {
      if (currentStep < 7) {
        setErrorField(false);
        setCurrentStep(currentStep + 1);
      }

      if (currentStep === 7) {
        setStep(4);
      }

      scrollingTop();
    }
    // if (currentStep < 6) {
    //   setErrorField(false);
    //   setCurrentStep(currentStep + 1);
    // } else {
    //   setErrorField(false);
    //   setIsDoneCompleting(true);
    // }
  };

  const contentStep = useMemo(() => {
    switch (+currentStep) {
      case 1:
        return (
          <FirstStep
            options={options}
            dataStepOne={dataStepOne}
            setDataStepone={setDataStepone}
            disableInput={disableInput}
            isPhoneVerified={isPhoneVerified}
            setIsPhoneVerified={setIsPhoneVerified}
            loadingPhoneVerify={loadingPhoneVerify}
            onClickPhoneVerification={onClickPhoneVerification}
            disablePhoneVerification={disablePhoneVerification}
            errorField={errorField}
          />
        );
      case 2:
        return (
          <SecondStep
            form={familyMembers}
            setForm={setFamilyMembers}
            options={options}
            errorField={errorField}
          />
        );
      case 3:
        return (
          <ThirdStep
            form={educationHistory}
            setForm={setEducationHistory}
            options={options}
            errorField={errorField}
          />
        );
      case 4:
        return (
          <FourthStep
            form={jobHistory}
            setForm={setJobHistory}
            errorField={errorField}
          />
        );
      case 5:
        return (
          <FifthStep
            form={performanceHistory}
            setForm={setPerformanceHistory}
            errorField={errorField}
          />
        );
      case 6:
        return (
          <SixthStep
            form={documents}
            setForm={setDocuments}
            isDoneCompleting={false}
            setIsDoneCompleting={() => {}}
            handleSend={() => {}}
            errorField={errorField}
          />
        );

      case 7:
        return (
          <SignContract
            state={dataToSend}
            setState={setDataToSend}
            setStep={setStep}
            isExtend
          />
        );

      default:
        break;
    }
  }, [
    setStep,
    dataToSend,
    setDataToSend,
    currentStep,
    options,
    dataStepOne,
    setDataStepone,
    disableInput,
    isPhoneVerified,
    setIsPhoneVerified,
    loadingPhoneVerify,
    onClickPhoneVerification,
    disablePhoneVerification,
    errorField,
    familyMembers,
    setFamilyMembers,
    educationHistory,
    setEducationHistory,
    jobHistory,
    setJobHistory,
    setPerformanceHistory,
    performanceHistory,
    documents,
    setDocuments,
  ]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header} ref={stepperRef}>
        <div className={Styles.topHeader}>
          <div>
            <img src={Images.KERJA365_LIGHT} alt="" />
          </div>
          <div>
            <div>
              <img src={Images.PERMATA_LOGO} alt="permata-logo" />
            </div>
            <div>
              <span>Powered by</span>
              <span>Permata Indo Sejahtera</span>
            </div>
          </div>
        </div>

        <div className={Styles.stepControllerWrapper}>
          <button onClick={onPrevious}>
            <Icon icon="arrow-left" size={20} />
          </button>
          <div>
            <Stepper
              type="filled"
              onClick={handleClickStep}
              options={stepsOptions}
              currentStep={currentStep}
            />
          </div>
          <button onClick={onNext}>
            <Icon icon="arrow-right" size={20} />
          </button>
        </div>
      </div>

      {isLoadingDataSync || isLoadingCandidateExtendSync ? (
        <div
          style={{
            height: `calc(100vh - ${stepperHeight}px)`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingAnimation />
        </div>
      ) : (
        <div
          className={Styles.mainWrapper}
          style={{ height: `calc(100vh - ${stepperHeight}px)` }}
        >
          <div className={Styles.onScroll} ref={targetElement}></div>
          <div className={Styles.info}>
            <div>
              <img
                alt=""
                src={Images.BIG_C}
                className={`${Styles.big} ${Styles.c1}`}
              />
              <img
                alt=""
                src={Images.BIG_C}
                className={`${Styles.big} ${Styles.c2}`}
              />
              <div className={Styles.logo}>
                <Icon icon={"alert-solid"} size={24} color={"#FFFFFF"} />
              </div>
              <div className={Styles.desc}>
                <span>Informasi Penting</span>
                <span>
                  Mohon agar Anda dapat mengecek kembali data Anda yang telah
                  tercatat. Jika terdapat informasi yang perlu diperbarui atau
                  diubah, Anda dipersilakan untuk melakukan update sesuai
                  kebutuhan.
                </span>
              </div>
            </div>

            <div>
              <img
                alt=""
                src={Images.BIG_C}
                className={`${Styles.small} ${Styles.c3}`}
              />

              <div className={`${Styles.logo} ${Styles.none}`}></div>
              <div className={Styles.notes}>
                <span>NOTE : </span>
                <span>Mohon untuk tidak refresh halaman ini</span>
              </div>
            </div>
          </div>

          {errorField ? (
            <div className={Styles.errorBox}>
              <div>
                <Icon icon={"triangle-attention"} size={20} color={"#FF3E13"} />
              </div>
              <span>
                Terdapat data yang belum diisi, silahkan lengkapi data dibawah
                untuk dapat melanjutkan ke step selanjutnya
              </span>
            </div>
          ) : null}

          <div className={Styles.contentWrapper}>{contentStep}</div>

          <div className={Styles.buttonWrapper}>
            <span className={Styles.next} onClick={onNext}>
              Selanjutnya
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
