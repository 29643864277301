/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import { removeZeroStarting } from "@Helpers/removeZeroStarting";
import useQuery from "@Hooks/useQuery";
import OTPComponent from "@Molecule/OTPInput";
import { login, resendOTP, verifyOTP } from "@Services/auth";
import Images from "@Theme/Images";
import { useEffect, useMemo, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export const loader = () => {
  // const accessToken = sessionStorage.getItem("accessToken");

  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    throw redirect("/");
  }

  return null;
};

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const query = useQuery();

  const [nrk, setNRK] = useState("");
  const [value, setValue] = useState("");
  const [alert, setAlert] = useState(null);
  const [otpAlert, setOtpAlert] = useState(null);

  const [onLogin, setOnLogin] = useState(false);
  const [onOTPRequest, setOnOTPRequest] = useState(false);
  const [onVerify, setOnVerify] = useState(false);

  const [ableResend, setAbleResend] = useState(false);

  const [time, setTime] = useState("01:00");

  const step = query.get("s");
  const phone = query.get("p");
  const nrkNumber = query.get("c") ? removeZeroStarting(query.get("c")) : "";
  const hash = query.get("h");

  const [strokeDasharray, setStrokeDasharray] = useState("283 283");

  const filler = useMemo(() => {
    const arr = [];
    for (let i = 3; i < phone?.length - 3; i++) {
      arr.push("&#x2022;");
    }
    return arr;
  }, [phone]);

  function formatTimeLeft(timeLeft) {
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  const TIME_LIMIT = 60;

  let timePassed = 0;
  let timeLeft = TIME_LIMIT;

  //eslint-disable-next-line no-unused-vars
  let timerInterval = null;
  function startTimer() {
    setAbleResend(false);
    timerInterval = setInterval(() => {
      timePassed = timePassed += 1;
      timeLeft = TIME_LIMIT - timePassed;

      // The time left label is updated
      if (timeLeft >= 0) {
        setTime(formatTimeLeft(timeLeft));

        setCircleDasharray();
      }
    }, 1000);
  }

  function calculateTimeFraction() {
    return timeLeft / TIME_LIMIT;
  }

  // Update the dasharray value as time passes, starting with 283
  function setCircleDasharray() {
    const circleDasharray = `${(calculateTimeFraction() * 283).toFixed(0)} 283`;

    setStrokeDasharray(circleDasharray);

    if (circleDasharray.split(" ")[0] === "0") {
      setAbleResend(true);
    }
  }

  useEffect(() => {
    if (step) {
      startTimer();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const COLOR_CODES = {
    info: {
      color: "green",
    },
  };

  let remainingPathColor = COLOR_CODES.info.color;

  const handleSubmit = async (e) => {
    const formattedNRK = removeZeroStarting(`${nrk}`);
    try {
      e.preventDefault();
      setOnLogin(true);
      const { response } = await login({ employeeId: formattedNRK });
      setOnLogin(false);
      navigate(
        `${pathname}?s=OTP&h=${response.hash}&p=${response.phoneNumber}&c=${nrk}`
      );
    } catch (err) {
      console.log(err?.response?.data?.error);
      const error = err.response?.data?.error;
      if (error?.toLowerCase()?.includes("invalid employeeid")) {
        setAlert("employee ID not found");
      } else {
        setAlert(error || "something went wrong");
      }
      setOnLogin(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    try {
      setOnVerify(true);
      e.preventDefault();
      const response = await verifyOTP(hash, { otp: value });
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("username", response.data.name);
      localStorage.setItem(
        "role",
        response.data.role === "REGIONAL BUSINESS MANAGER" ||
          response.data.role?.toLowerCase() === "rbm"
          ? "REGION BUSINESS MANAGER"
          : response.data.role
        // : response.data.jobLevel
      );
      localStorage.setItem("profilePicture", response.data.profilePicture);
      localStorage.setItem("id", response?.data?.employeeId);
      localStorage.setItem("currentMode", "dashboard");
      localStorage.setItem("isClient", "false");
      // sessionStorage.setItem("accessToken", response.accessToken);
      // sessionStorage.setItem("username", response.data.name);
      // sessionStorage.setItem("role", 'RECRUITMENT OFFICER'); -- not used
      // sessionStorage.setItem("role", response.data.jobLevel);
      // sessionStorage.setItem("profilePicture", response.data.profilePicture);
      // sessionStorage.setItem("id", response?.data?.employeeId);
      // sessionStorage.setItem("currentMode", "dashboard");
      // sessionStorage.setItem("isClient", "false");
      setOnVerify(false);
      // if (response.data.jobLevel === "RECRUITMENT OFFICER") {
      if (response.data.role === "RECRUITMENT OFFICER") {
        navigate("/rekrutmen");
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log(err?.response?.data?.error);
      const error = err.response?.data?.error;
      setOnVerify(false);
      setOtpAlert(
        error?.toLowerCase()?.includes("wrong code")
          ? "Wrong OTP Code"
          : "OTP Expired. Please send request again."
      );

      if (error?.toLowerCase()?.includes("wrong code")) {
        setOtpAlert("Wrong OTP Code");
      } else if (error?.toLowerCase()?.includes("expired")) {
        setOtpAlert("OTP Expired. Please send request again.");
        setAbleResend(true);
      } else {
        setOtpAlert(error || "something went wrong");
      }
    }
  };

  const handleResendRequest = async (e) => {
    try {
      setOnOTPRequest(true);
      e.preventDefault();
      const { response } = await resendOTP(nrkNumber);
      navigate(
        `${pathname}?s=OTP&h=${response.hash}&p=${response.phoneNumber}&c=${nrkNumber}`,
        { replace: true }
      );
      setOnOTPRequest(false);
      setAlert(null);
      setOtpAlert(null);
    } catch (err) {
      setOnOTPRequest(false);
      console.log(err);
    }
  };
  return (
    <div className={Styles.container}>
      <img src={Images.AUTH_BG} alt="bg" className={Styles.bG} />
      {!step ? (
        <div className={Styles.box}>
          <div className={Styles.logo}>
            <img src={Images.KERJA365_LIGHT} alt="logo" />
          </div>
          <div className={Styles.header}>
            <div className={Styles.top}>
              <h3>Hi Welcome to Kerja 365</h3>
              <img src={Images.WAVE} alt="hi" />
            </div>
            <div className={Styles.bottom}>
              <p>Log in with your NRK</p>
            </div>
          </div>
          <div className={Styles.content}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="nrk">NRK</label>
              <input
                value={nrk}
                className={alert ? Styles.alert : ""}
                onChange={(e) => {
                  setAlert(null);
                  setNRK(e.target.value);
                }}
                type="text"
                placeholder="Type your NRK"
              />
              <span className={alert ? Styles.alertText : Styles.noAlert}>
                {alert || "no alert"}
              </span>
              <div className={Styles.buttonWrapper}>
                <button disabled={!nrk || onLogin} type="submit">
                  {onLogin ? "Logging In..." : "Log In"}
                </button>
              </div>
              {/* <DownloadOption /> */}
            </form>
          </div>
        </div>
      ) : (
        <div className={Styles.box}>
          <div className={Styles.logo}>
            <img src={Images.KERJA365_LIGHT} alt="logo" />
          </div>
          <div className={Styles.header}>
            <div className={Styles.top}>
              <h3>OTP Confirmation</h3>
            </div>
            <div className={Styles.bottom}>
              <p>
                {`Enter the OTP code we sent to your registered phone number +`}
                {phone?.slice(0, 3)}
                {filler?.map((each, i) => {
                  return <span key={i}>&#x2022;</span>;
                })}
                {phone?.slice(phone?.length - 3, phone?.length)}
              </p>
            </div>
          </div>
          <div className={Styles.content}>
            <form onSubmit={handleVerifyOTP}>
              <OTPComponent
                onChange={(value) => {
                  setValue(value);
                  setOtpAlert(null);
                }}
                alert={otpAlert}
              />
              <span className={otpAlert ? Styles.alertText : Styles.noAlert}>
                {otpAlert || "no alert"}
              </span>

              <div className={Styles.resendWrapper}>
                <div className={Styles.resendController}>
                  <button
                    type="button"
                    disabled={!ableResend}
                    onClick={handleResendRequest}
                    className={
                      ableResend ? Styles.ableResend : Styles.disableResend
                    }
                  >
                    {onOTPRequest ? "Requesting OTP" : "Request again?"}
                  </button>
                </div>
                <div className={Styles.timerWrapper}>
                  <div className={Styles.baseTimer}>
                    <svg
                      className={Styles.SVG}
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g className={Styles.circle}>
                        <circle
                          className={Styles.pathElapsed}
                          cx="50"
                          cy="50"
                          r="45"
                        />
                        <path
                          id="base-timer-path-remaining"
                          strokeDasharray={strokeDasharray}
                          className={`${Styles.pathRemaining} ${remainingPathColor}`}
                          d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <span>{time}</span>
                </div>
              </div>
              <div className={Styles.buttonWrapper}>
                <button type="submit" disabled={value?.length < 4 || onVerify}>
                  {onVerify ? "Submitting..." : "Submit"}
                </button>
              </div>
              {/* <DownloadOption /> */}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line
const DownloadOption = () => {
  const handleDownload = (e) => {
    e.preventDefault();
  };
  return (
    <div className={Styles.downloadContainer}>
      <Icon icon="information-outline" size={20} color={"#1571DE"} />
      <p>Untuk mendownload tutorial,&nbsp;</p>
      <button type="button" onClick={handleDownload}>
        Klik di sini
      </button>
    </div>
  );
};
