/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import { makeRandomString } from "@Helpers/makeRandomString";
import NewAddPositionModal from "@Molecule/_modal/NewAddPositionModal";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import { Grid, InfoCard } from "../NewSixth/Second";
import Switch from "@Atom/Switch";
import DelegationsModal from "@Molecule/_modal/DelegationsModal";
import { InputTitle } from "@Molecule/InputField";
import DecorativeInput from "@Atom/DecorativeInput";
import DinamicDecorativeInputField from "@Molecule/DinamicDecorativeInputField";
import InputDateField from "@Molecule/InputDateField";
import MultiSelectSmallLabel from "@Molecule/MultiSelectSmallLabel";
import Dropdown from "@Atom/DropDown";
import Counter from "@Atom/Counter";
import { BPURangeSalary } from "@Helpers/BPURangeSalary";
import useWindowSize from "@Hooks/useWindowSize";
import OrderResponsiveModal from "../OrderResponsiveModal";

export default function PositionStep({
  delegationsForm = [],
  setDelegationsForm = () => {},
  positionsForm = [],
  setPositionsForm = () => {},
  headerHeight,
  alertMessage = [],
  qualificationOptions = [],
  selectedButtonBPJS = "",
  selectedValueBPU = null,
  personalEquipmentOptions = [],
  allowanceOptions = [],
  activePositionID,
  setActivePositionID,
  activeExactPositionID,
  setActiveExactPositionID,
  isProcced = false,
}) {
  const [showAdd, setShowAdd] = useState(false);
  const [selectedPositionToEdit, setSelectedPositionToEdit] = useState(null);
  const [search, setSearch] = useState("");
  const [showResponsive, setShowResponsive] = useState(false);

  const { width } = useWindowSize();

  // console.log(activeExactPositionID, "test", activePositionID);

  const [generalRO, setGeneralRO] = useState(null);

  useEffect(() => {
    if (positionsForm?.length > 0 && !activePositionID) {
      setActivePositionID(positionsForm[0]?.id);
    }
  }, [activePositionID, positionsForm, setActivePositionID]);

  useEffect(() => {
    if (
      delegationsForm?.length > 0 &&
      !activeExactPositionID &&
      activePositionID
    ) {
      const found = positionsForm?.find((obj) => obj?.id === activePositionID);
      if (found?.citiesData?.length) {
        setActiveExactPositionID(found?.id + "-" + found?.citiesData[0]?.id);
      }
    }
  }, [
    activeExactPositionID,
    activePositionID,
    delegationsForm?.length,
    positionsForm,
    setActiveExactPositionID,
  ]);

  const handleChangePosition = (id, name, value) => {
    setPositionsForm(
      positionsForm?.map((obj) =>
        obj?.id === id
          ? {
              ...obj,
              [name]: value,
            }
          : {
              ...obj,
              ...(name === "isExpanded" && { isExpanded: false }),
            }
      )
    );
  };

  const renewRO = useCallback(() => {
    // eslint-disable-next-line eqeqeq
    if (
      generalRO?.id &&
      positionsForm?.find((obj) => obj?.dedicatedROId != generalRO?.id)
    ) {
      setPositionsForm(
        positionsForm?.map((p) => {
          return {
            ...p,
            dedicatedROId: generalRO?.id,
            dedicatedROName: generalRO?.name,
          };
        })
      );
    }
  }, [generalRO?.id, generalRO?.name, positionsForm, setPositionsForm]);

  useEffect(() => {
    renewRO();
  }, [renewRO]);

  const handleSalaryChange = useCallback(
    (positionId, newSalary, cityCode, type) => {
      if (type === "denom") {
        setPositionsForm((prevPositions) =>
          prevPositions.map((position) => {
            if (position.id === positionId) {
              const updatedCitiesData = position?.citiesData.map((city) => {
                if (cityCode === city?.cityCode) {
                  const { jkk, jkm, jht } = BPURangeSalary(
                    city?.salaryValue || 0,
                    newSalary || "Bulanan"
                  );
                  return {
                    ...city,
                    salaryDenom: newSalary,
                    jkk:
                      !city?.isJkkManual && newSalary
                        ? selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                          ? `${jkk / 2}`
                          : jkk
                        : city?.jkk,
                    jkm:
                      !city?.isJkmManual && newSalary
                        ? selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                          ? `${jkm / 2}`
                          : jkm
                        : city?.jkm,
                    jht:
                      !city?.isJhtManual && newSalary
                        ? selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                          ? `${jht / 2}`
                          : jht
                        : city?.jht,
                    jkk1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? !city?.isJkk1Manual && newSalary
                          ? `${jkk / 2}`
                          : city?.jkk1
                        : "0",

                    jkm1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? !city?.isJkm1Manual && newSalary
                          ? `${jkm / 2}`
                          : city?.jkm1
                        : "0",
                    jht1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? !city?.isJht1Manual && newSalary
                          ? `${jht / 2}`
                          : city?.jht1
                        : "0",
                  };
                }

                return city;
              });

              return {
                ...position,
                citiesData: updatedCitiesData,
              };
            }
            return position; // Return unchanged position
          })
        );
      } else {
        setPositionsForm((prevPositions) =>
          prevPositions.map((position) => {
            if (position.id === positionId) {
              const updatedCitiesData = position?.citiesData.map((city) => {
                if (cityCode === city?.cityCode) {
                  const { jkk, jkm, jht } = BPURangeSalary(
                    newSalary,
                    city?.salaryDenom || "Bulanan"
                  );
                  return {
                    ...city,
                    salaryValue: newSalary,
                    jkk:
                      !city?.isJkkManual && newSalary
                        ? selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                          ? `${jkk / 2}`
                          : jkk
                        : city?.jkk,
                    jkm:
                      !city?.isJkmManual && newSalary
                        ? selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                          ? `${jkm / 2}`
                          : jkm
                        : city?.jkm,
                    jht:
                      !city?.isJhtManual && newSalary
                        ? selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                          ? `${jht / 2}`
                          : jht
                        : city?.jht,
                    jkk1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? !city?.isJkk1Manual && newSalary
                          ? `${jkk / 2}`
                          : city?.jkk1
                        : "0",

                    jkm1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? !city?.isJkm1Manual && newSalary
                          ? `${jkm / 2}`
                          : city?.jkm1
                        : "0",
                    jht1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? !city?.isJht1Manual && newSalary
                          ? `${jht / 2}`
                          : city?.jht1
                        : "0",
                  };
                }

                return city;
              });

              return {
                ...position,
                citiesData: updatedCitiesData,
              };
            }
            return position; // Return unchanged position
          })
        );
      }
    },
    [selectedValueBPU, setPositionsForm]
  );

  const handleBPUChange = useCallback(
    (positionId, newVal, type, cityCode) => {
      // Mapping for manual flags based on type
      const manualFlags = {
        jkk: "isJkkManual",
        jkm: "isJkmManual",
        jht: "isJhtManual",
        jkk1: "isJkk1Manual",
        jkm1: "isJkm1Manual",
        jht1: "isJht1Manual",
      };

      // Check if the type is valid
      if (!manualFlags[type]) return;

      setPositionsForm((prevPositions) =>
        prevPositions.map((position) => {
          if (position.id === positionId) {
            const updatedCitiesData = position.citiesData.map((city) => {
              if (city.cityCode === cityCode) {
                return {
                  ...city,
                  [type]: newVal, // Update the specific type value
                  [manualFlags[type]]: true, // Set the manual flag based on type
                };
              }
              return city;
            });

            return {
              ...position,
              citiesData: updatedCitiesData,
            };
          }
          return position;
        })
      );
    },
    [setPositionsForm]
  );

  return (
    <div
      id="Daftar Posisi"
      className={Styles.container}
      style={{
        maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)`,
      }}
    >
      {width <= 768 ? (
        <div
          className={Styles.buttonResponsive}
          onClick={() => {
            setShowResponsive(true);
          }}
        >
          <Icon icon={"contracted"} size={16} color={"#FFFFFF"} />
        </div>
      ) : null}

      {width > 768 ? (
        <div
          style={{
            maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)`,
          }}
          className={Styles.index}
        >
          <div className={Styles.iHeader}>
            <div className={Styles.iHLogo}>
              <Icon icon={"contracted"} size={16} color={"#1571DE"} />
            </div>
            <span>Daftar Posisi</span>
          </div>
          {!!positionsForm?.length ? (
            <div className={Styles.iContent}>
              <div className={Styles.icHeader}>
                {!isProcced && (
                  <button onClick={() => setShowAdd(true)}>
                    <Icon icon="add-circle" size={20} />
                    <span>Tambah Posisi</span>
                  </button>
                )}

                <div className={Styles.searchWrapper}>
                  <SearchBar
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                    placeholder={"Cari Posisi"}
                  />
                </div>
              </div>

              <div className={Styles.iList}>
                {positionsForm
                  ?.filter((obj) =>
                    obj?.positionName
                      ?.toUpperCase()
                      ?.includes(search?.toUpperCase())
                  )
                  ?.map((d, dI) => (
                    <div key={dI} className={`${Styles.each}`}>
                      <div
                        // eslint-disable-next-line eqeqeq
                        className={`${Styles.eHeader} ${
                          alertMessage?.find(
                            (el) =>
                              el?.remark === "Daftar Posisi" &&
                              el?.name === "Dedicated RO" &&
                              el?.positionId === d?.id
                          ) && Styles.error
                        }  ${
                          Styles[activePositionID == d?.id ? "active" : ""]
                        }`}
                        onClick={() => {
                          setActivePositionID(d?.id);
                          handleChangePosition(d?.id, "isExpanded", true);
                          setActiveExactPositionID("");
                        }}
                      >
                        <div className={Styles.ehTop}>
                          <Icon icon={"bag-job"} size={20} />
                          <span>{d?.positionName}</span>
                          <div className={Styles?.cityAmount}>
                            {
                              d?.citiesData?.filter((obj) => obj?.isApplied)
                                ?.length
                            }
                          </div>
                          <button
                            onClick={(e) => {
                              e?.stopPropagation();
                              handleChangePosition(
                                d?.id,
                                "isExpanded",
                                !d?.isExpanded
                              );
                            }}
                          >
                            <Icon
                              icon={d?.isExpanded ? "arrow-up" : "arrow-down"}
                              size={20}
                            />
                          </button>
                        </div>
                        <div className={Styles.ehBottom}>
                          <Icon icon={"business-man"} size={20} />
                          <span>{d?.dedicatedROName}</span>
                          {!d?.preventDelete && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setPositionsForm(
                                  positionsForm?.filter(
                                    (obj) => obj?.id != d?.id
                                  )
                                );
                                setActivePositionID("");
                                setActiveExactPositionID("");
                              }}
                            >
                              <Icon
                                icon={"trash"}
                                size={18}
                                color={"#ff3e13"}
                              />
                            </button>
                          )}

                          {!isProcced && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedPositionToEdit(d);
                              }}
                            >
                              <Icon icon={"edit"} size={18} />
                            </button>
                          )}
                        </div>
                      </div>
                      {d?.isExpanded && (
                        <div className={Styles.eContent}>
                          <div className={Styles.eSearchWrapper}>
                            <OutlinedSearchBar
                              value={d?.search}
                              setValue={(newVal) =>
                                handleChangePosition(d?.id, "search", newVal)
                              }
                              placeholder={"Cari Kota"}
                            />
                          </div>
                          <div className={Styles.ecList}>
                            {d?.citiesData
                              ?.filter((obj) =>
                                d?.search
                                  ? obj?.cityName
                                      ?.toLowerCase()
                                      ?.includes(d?.search?.toLowerCase())
                                  : obj
                              )
                              ?.map((c, cI) => (
                                <div
                                  key={cI}
                                  className={`${Styles.ecCity} ${
                                    activeExactPositionID ===
                                    d?.id + "-" + c?.id
                                      ? Styles.active
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setActivePositionID(d?.id);
                                    setActiveExactPositionID(
                                      d?.id + "-" + c?.id
                                    );
                                  }}
                                >
                                  <div
                                    is-completed={
                                      c?.salaryValue &&
                                      c?.expectedDate &&
                                      c?.salaryDenom &&
                                      !c?.allowances?.find(
                                        (obj) => !obj?.value
                                      ) &&
                                      !c?.personalEquipments?.find(
                                        (obj) => !obj?.value
                                      )
                                        ? "true"
                                        : "false"
                                    }
                                    is-applied={c?.isApplied ? "true" : "false"}
                                  >
                                    <span>{c?.cityName}</span>
                                    {c?.isApplied && (
                                      <Icon
                                        icon={"check-circle-outline-v2"}
                                        size={16}
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className={Styles.iEmpty}>
              <img src={Images.HIGH_BUILDING} alt="" />
              <span>Belum ada data posisi ditambahkan</span>
            </div>
          )}
        </div>
      ) : null}

      <div
        style={{
          maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)`,
        }}
        className={Styles.main}
      >
        {positionsForm?.length ? (
          positionsForm?.map((d, dI) => (
            <PositionDataCard
              key={dI}
              d={d}
              delegationsForm={delegationsForm}
              setDelegationsForm={setDelegationsForm}
              setD={(name, value) => {
                setPositionsForm(
                  positionsForm?.map((obj) => {
                    if (d?.id === obj?.id) {
                      return {
                        ...obj,
                        [name]: value,
                      };
                    }
                    return obj;
                  })
                );
              }}
              isActive={activePositionID === d?.id}
              activeExactPositionID={activeExactPositionID}
              alertMessage={alertMessage}
              selectedButtonBPJS={selectedButtonBPJS}
              selectedValueBPU={selectedValueBPU}
              personalEquipmentOptions={personalEquipmentOptions}
              allowanceOptions={allowanceOptions}
              positionsForm={positionsForm}
              setPositionsForm={setPositionsForm}
              handleSalaryChange={handleSalaryChange}
              handleBPUChange={handleBPUChange}
            />
          ))
        ) : (
          <div className={Styles.noPosition}>
            <img src={Images.TIME_MANAGEMENT} alt="default" />
            <div className={Styles.desc}>
              <h3>Belum Ada List Position yang Ditambahkan</h3>
              <p>Silahkan untuk menambah manpower sesuai kebutuhanmu</p>
            </div>

            {!isProcced && (
              <button
                className={Styles.addLocationButton}
                onClick={() => setShowAdd(true)}
              >
                <Icon icon="circle-plus" size={20} />
                <span>Position</span>
              </button>
            )}
          </div>
        )}
      </div>

      {showResponsive && width <= 768 && (
        <OrderResponsiveModal
          type={"position"}
          handleClose={() => setShowResponsive(false)}
          positionsForm={positionsForm}
          setShowAdd={setShowAdd}
          search={search}
          setSearch={setSearch}
          activePositionID={activePositionID}
          setActivePositionID={setActivePositionID}
          handleChangePosition={handleChangePosition}
          setActiveExactPositionID={setActiveExactPositionID}
          setPositionsForm={setPositionsForm}
          setSelectedPositionToEdit={setSelectedPositionToEdit}
          activeExactPositionID={activeExactPositionID}
        />
      )}

      {showAdd && (
        <NewAddPositionModal
          show={showAdd}
          setShow={setShowAdd}
          onAdd={({
            positionName,
            dedicatedROId = "",
            dedicatedROName = "",
            qualifications = [],
          }) => {
            setPositionsForm([
              {
                id: makeRandomString(5),
                search: "",
                isExpanded: false,
                positionName,
                dedicatedROName: dedicatedROName,
                dedicatedROId: dedicatedROId,
                qualifications: qualifications,
                citiesData: delegationsForm?.map((d) => {
                  const salaryJobs = d?.umk || 4500000;
                  const { jkk, jkm, jht } = BPURangeSalary(
                    salaryJobs,
                    "Bulanan"
                  );
                  return {
                    id: d?.id,
                    isApplied: false,
                    cityCode: d?.cityCode,
                    cityName: d?.cityName,
                    umk: d?.umk,
                    amount: 1,
                    salaryValue: d?.umk || 4500000,
                    salaryDenom: "Bulanan",
                    personalEquipments: [],
                    allowances: [],
                    jkk:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? salaryJobs
                          ? `${+jkk / 2}`
                          : "0"
                        : salaryJobs
                        ? jkk
                        : "0",
                    jkm:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? salaryJobs
                          ? `${+jkm / 2}`
                          : "0"
                        : salaryJobs
                        ? jkm
                        : "0",
                    jht:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? salaryJobs
                          ? `${+jht / 2}`
                          : "0"
                        : salaryJobs
                        ? jht
                        : "0",
                    isJkkManual: false,
                    isJhtManual: false,
                    isJkmManual: false,
                    jkk1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? salaryJobs
                          ? `${+jkk / 2}`
                          : "0"
                        : "0",
                    jkm1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? salaryJobs
                          ? `${+jkm / 2}`
                          : "0"
                        : "0",
                    jht1:
                      selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                        ? salaryJobs
                          ? `${+jht / 2}`
                          : "0"
                        : "0",
                    isJkk1Manual: false,
                    isJht1Manual: false,
                    isJkm1Manual: false,
                    expectedDate: "",
                  };
                }),
              },
              ...positionsForm,
            ]);
          }}
          existingPositionNames={positionsForm?.map((obj) => obj?.positionName)}
          delegationsForm={delegationsForm}
          alertMessage={alertMessage}
          qualificationOptions={qualificationOptions}
          generalDedicatedRO={generalRO}
          setGeneralDedicatedRO={setGeneralRO}
        />
      )}

      {selectedPositionToEdit && (
        <NewAddPositionModal
          show={selectedPositionToEdit}
          setShow={() => setSelectedPositionToEdit(null)}
          defaultPositionData={selectedPositionToEdit}
          editMode
          onAdd={({
            positionName,
            dedicatedROId = "",
            dedicatedROName = "",
            qualifications = [],
          }) => {
            setPositionsForm(
              positionsForm?.map((p) => {
                if (p?.id === selectedPositionToEdit?.id) {
                  return {
                    ...p,
                    positionName,
                    dedicatedROName: dedicatedROName,
                    dedicatedROId: dedicatedROId,
                    qualifications: qualifications,
                  };
                } else {
                  return p;
                }
              })
            );
          }}
          existingPositionNames={positionsForm?.map((obj) => obj?.positionName)}
          delegationsForm={delegationsForm}
          alertMessage={alertMessage}
          qualificationOptions={qualificationOptions}
          generalDedicatedRO={generalRO}
          setGeneralDedicatedRO={setGeneralRO}
        />
      )}
    </div>
  );
}

const PositionDataCard = ({
  d,
  setDelegationsForm = () => {},
  setD = () => {},
  isActive,
  activeExactPositionID = "",
  delegationsForm = [],
  alertMessage = "",
  selectedButtonBPJS = "",
  selectedValueBPU = null,
  personalEquipmentOptions = [],
  allowanceOptions = [],
  positionsForm,
  setPositionsForm,
  handleSalaryChange,
  handleBPUChange,
}) => {
  const [expandQualifications, setExpandQualifications] = useState(false);

  return (
    <div
      className={`${Styles.positionData} ${
        Styles[isActive ? "visible" : "hidden"]
      }`}
    >
      <div className={Styles.qCard}>
        <div className={Styles.qHeader}>
          <div className={Styles.qhLeft}>
            <span>Job Qualification</span>
            {!expandQualifications && (
              <div>
                <Icon icon={"information-solid"} size={20} color={"#1571DE"} />
                <p>
                  Terdapat <span>{d?.qualifications?.length}</span> Job
                  Qualification Aktif
                </p>
              </div>
            )}
          </div>
          <button
            onClick={() => setExpandQualifications(!expandQualifications)}
          >
            <Icon
              icon={expandQualifications ? "arrow-up" : "arrow-down"}
              size={24}
            />
          </button>
        </div>
        {expandQualifications && (
          <div className={Styles.qContent}>
            <Grid>
              {d?.qualifications?.map((q, qI) => (
                <InfoCard
                  key={qI}
                  title={q?.name}
                  description={q?.value}
                  backgroundColor="#f5f5f5"
                />
              ))}
            </Grid>
            <button onClick={() => setExpandQualifications(false)}>
              <span>Tampilkan Lebih Sedikit</span>
              <Icon icon={"arrow-up"} size={20} />
            </button>
          </div>
        )}
      </div>

      {d?.citiesData?.map((c, cI) => (
        <CityDataCard
          key={cI}
          c={c}
          delegationsForm={delegationsForm}
          setDelegationsForm={setDelegationsForm}
          setC={(name, value) => {
            setD(
              "citiesData",
              d?.citiesData?.map((obj) => {
                if (obj?.cityCode === c?.cityCode) {
                  return {
                    ...obj,
                    [name]: value,
                  };
                }
                return obj;
              })
            );
          }}
          // eslint-disable-next-line eqeqeq
          isActive={c?.id == activeExactPositionID?.split("-")[1]}
          cityDeployments={delegationsForm?.find(
            (obj) => obj?.cityCode === c?.cityCode
          )}
          alertMessage={alertMessage}
          selectedButtonBPJS={selectedButtonBPJS}
          selectedValueBPU={selectedValueBPU}
          personalEquipmentOptions={personalEquipmentOptions}
          allowanceOptions={allowanceOptions}
          positionsForm={positionsForm}
          setPositionsForm={setPositionsForm}
          handleSalaryChange={handleSalaryChange}
          d={d}
          handleBPUChange={handleBPUChange}
        />
      ))}
    </div>
  );
};

const CityDataCard = ({
  c,
  delegationsForm = [],
  setDelegationsForm = () => {},
  setC = () => {},
  isActive,
  cityDeployments = [],
  personalEquipmentOptions = [],
  allowanceOptions = [],
  selectedValueBPU = null,
  selectedButtonBPJS = "",
  alertMessage = "",
  handleSalaryChange,
  handleBPUChange,
  d,
}) => {
  const [showDelegations, setShowDelegations] = useState(false);

  const template = useMemo(() => {
    return [
      {
        title: "Salary & Allowance Setiap Manpower",
        component: (
          <div className={`${Styles.salarySection} ${Styles.grid}`}>
            <div className={Styles.salary}>
              <InputTitle title={"Salary"} />
              <div className={Styles.salaryFields} id="Salary">
                <DecorativeInput
                  value={c?.salaryValue}
                  setValue={(newVal) => {
                    handleSalaryChange(d?.id, newVal, c?.cityCode, "salary");
                  }}
                  adornment="Rp"
                  type="currency"
                  placeholder="Salary"
                  disabled={!c?.isApplied}
                  isError={
                    alertMessage?.find(
                      (el) =>
                        el?.remark === "Salary" &&
                        el?.cityId === c?.id &&
                        el?.positionId === d?.id
                    ) && !c?.salaryValue
                  }
                />
              </div>
            </div>
            <div className={Styles.salary} id="SalaryDenom">
              {/* <InputTitle title={"Salary"} /> */}
              <span style={{ paddingBottom: "18px" }} />
              <Dropdown
                isError={
                  alertMessage?.find(
                    (el) =>
                      el?.remark === "SalaryDenom" &&
                      el?.cityId === c?.id &&
                      el?.positionId === d?.id
                  ) && !c?.salaryDenom
                }
                options={["Harian", "Mingguan", "Bulanan"]?.map((obj) => {
                  return {
                    name: obj,
                  };
                })}
                placeholder={"Harian/mingguan/bulanan"}
                value={c?.salaryDenom}
                setValue={(newVal) =>
                  handleSalaryChange(d?.id, newVal, c?.cityCode, "denom")
                }
                autoClose
                disabled={!c?.isApplied}
              />
            </div>

            <div className={Styles.equipments}>
              <MultiSelectSmallLabel
                title="Personal Work Equipment"
                placeholder="Select"
                options={personalEquipmentOptions?.map((obj) => {
                  return {
                    // id: makeRandomString(5),
                    name: obj,
                    value: "",
                  };
                })}
                labels={c?.personalEquipments}
                setLabels={(newLabels) => setC("personalEquipments", newLabels)}
                disabled={!c?.isApplied}
              />
              {!!c?.personalEquipments?.length && (
                <div className={Styles.multifieldsWrapper}>
                  {c?.personalEquipments?.map((p, pI) => (
                    <div id={`Personal Equipment ${p?.name}`}>
                      <DinamicDecorativeInputField
                        key={pI}
                        disabled={!c?.isApplied}
                        onChange={(_, newVal) =>
                          setC(
                            "personalEquipments",
                            c?.personalEquipments?.map((obj) => {
                              if (p?.name === obj?.name) {
                                return {
                                  ...obj,
                                  value: newVal,
                                };
                              }
                              return obj;
                            })
                          )
                        }
                        onDelete={() =>
                          setC(
                            "personalEquipments",
                            c?.personalEquipments?.filter(
                              (obj) => obj?.name !== p?.name
                            )
                          )
                        }
                        isError={
                          alertMessage?.length > 0 &&
                          alertMessage?.find(
                            (el) =>
                              el?.remark?.includes(p?.name) &&
                              el?.cityId === c?.id &&
                              el?.positionId === d?.id
                          ) &&
                          !p?.value
                        }
                        name={p?.name}
                        value={p?.value || ""}
                        title={p?.name}
                        adornment="Rp"
                        type="currency"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={Styles.allowances}>
              <MultiSelectSmallLabel
                title="Allowance"
                placeholder="Select"
                disabled={!c?.isApplied}
                options={
                  allowanceOptions?.length
                    ? allowanceOptions?.map((obj) => {
                        return {
                          // id: makeRandomString(5),
                          name: obj,
                          value: "",
                          denom: "Bulanan",
                        };
                      })
                    : []
                }
                preventDeleteIndicator={"disableDelete"}
                labels={c?.allowances?.filter((obj) => obj?.type !== "BPU")}
                setLabels={(newLabels) => setC("allowances", newLabels)}
              />
              {!!c?.allowances?.length && (
                <div className={Styles.multifieldsWrapper}>
                  {c?.allowances?.map((p, pI) => (
                    <div
                      className={Styles.multifields}
                      id={`Allowances ${p?.name}`}
                    >
                      <div className={Styles.inputForm}>
                        <DinamicDecorativeInputField
                          key={pI}
                          disabled={!c?.isApplied}
                          onChange={(_, newVal) =>
                            setC(
                              "allowances",
                              c?.allowances?.map((obj) => {
                                if (p?.name === obj?.name) {
                                  return {
                                    ...obj,
                                    value: newVal,
                                  };
                                }
                                return obj;
                              })
                            )
                          }
                          onDelete={() =>
                            setC(
                              "allowances",
                              c?.allowances?.filter(
                                (obj) => obj?.name !== p?.name
                              )
                            )
                          }
                          isError={
                            alertMessage?.length > 0 &&
                            alertMessage?.find(
                              (el) =>
                                el?.remark?.includes(p?.name) &&
                                el?.cityId === c?.id &&
                                el?.positionId === d?.id
                            ) &&
                            !p?.value
                          }
                          name={p?.name}
                          value={p?.value || ""}
                          title={p?.name}
                          adornment="Rp"
                          type="currency"
                          preventDelete={true}
                          // preventDelete={p?.disableDelete}
                        />
                      </div>
                      <div className={Styles.inputForm}>
                        <div className={Styles.dropdown}>
                          <Dropdown
                            options={["Harian", "Mingguan", "Bulanan"]?.map(
                              (obj) => {
                                return {
                                  name: obj,
                                };
                              }
                            )}
                            disabled={!c?.isApplied}
                            placeholder={"Harian/mingguan/bulanan"}
                            value={p?.denom || "Bulanan"}
                            setValue={(newVal) =>
                              setC(
                                "allowances",
                                c?.allowances?.map((obj) => {
                                  if (p?.name === obj?.name) {
                                    return {
                                      ...obj,
                                      denom: newVal,
                                    };
                                  }
                                  return obj;
                                })
                              )
                            }
                            autoClose
                          />
                        </div>
                        <button
                          disabled={!c?.isApplied}
                          className={Styles.iconDelete}
                          onClick={() => {
                            setC(
                              "allowances",
                              c?.allowances?.filter(
                                (obj) => obj?.name !== p?.name
                              )
                            );
                          }}
                        >
                          <Icon icon={"trash"} size={20} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "BPJS (BPU)",
        extraTitle: <div className={Styles.valueBPU}>{selectedValueBPU}</div>,
        component: (
          <div className={Styles.bpuBox}>
            <div className={Styles.bpuWrapper}>
              {selectedValueBPU === "Tagih Ke Perusahaan & Karyawan" ? (
                <div className={Styles.titleBox}>
                  <span className={Styles.left}>Karyawan</span>
                  <div className={Styles.right}>
                    <div className={Styles.icon}>
                      <Icon icon={"alert-solid"} size={16} color={"#757575"} />
                    </div>
                    <span className={Styles.desc}>
                      Default persentase biaya BPJS, akan otomatis dibagi antara
                      perusahaan dan karyawan
                    </span>
                    <div className={Styles.percentage}>
                      <span>50</span>
                      <span>%</span>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className={`${Styles.bpuSection} ${Styles.grid}`}>
                <DinamicDecorativeInputField
                  title={"JKK (Jaminan Kelengkapan Kerja)"}
                  value={
                    selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                      ? c?.jkk1
                      : c?.jkk
                  }
                  onChange={(_, newVal) => {
                    if (selectedValueBPU === "Tagih Ke Perusahaan & Karyawan") {
                      handleBPUChange(d?.id, newVal || 0, "jkk1", c?.cityCode);
                    } else {
                      handleBPUChange(d?.id, newVal || 0, "jkk", c?.cityCode);
                    }
                  }}
                  adornment="Rp"
                  type="currency"
                  disabled={!c?.isApplied}
                  preventDelete
                />
                <DinamicDecorativeInputField
                  title={"JKM (Jaminan Kematian)"}
                  value={
                    selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                      ? c?.jkm1
                      : c?.jkm
                  }
                  onChange={(_, newVal) => {
                    if (selectedValueBPU === "Tagih Ke Perusahaan & Karyawan") {
                      handleBPUChange(d?.id, newVal || 0, "jkm1", c?.cityCode);
                    } else {
                      // setC("jkm", newVal);
                      // setC("isJkmManual", true);
                      handleBPUChange(d?.id, newVal || 0, "jkm", c?.cityCode);
                    }
                  }}
                  adornment="Rp"
                  type="currency"
                  disabled={!c?.isApplied}
                  preventDelete
                />
                <DinamicDecorativeInputField
                  title={"JHT (Jaminan Hari Tua)"}
                  value={
                    selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                      ? c?.jht1
                      : c?.jht
                  }
                  onChange={(_, newVal) => {
                    if (selectedValueBPU === "Tagih Ke Perusahaan & Karyawan") {
                      handleBPUChange(d?.id, newVal || 0, "jht1", c?.cityCode);
                    } else {
                      handleBPUChange(d?.id, newVal || 0, "jht", c?.cityCode);
                      // setC("isJhtManual", true);
                    }
                  }}
                  adornment="Rp"
                  type="currency"
                  disabled={!c?.isApplied}
                  preventDelete
                />
              </div>
            </div>

            {selectedValueBPU === "Tagih Ke Perusahaan & Karyawan" ? (
              <div className={Styles.bpuWrapper}>
                <div className={Styles.titleBox}>
                  <span className={Styles.left}>Perusahaan</span>
                </div>
                <div className={`${Styles.bpuSection} ${Styles.grid}`}>
                  <DinamicDecorativeInputField
                    title={"JKK (Jaminan Kelengkapan Kerja)"}
                    value={c?.jkk}
                    onChange={(_, newVal) => {
                      handleBPUChange(d?.id, newVal || 0, "jkk", c?.cityCode);
                    }}
                    adornment="Rp"
                    type="currency"
                    disabled={!c?.isApplied}
                    preventDelete
                  />
                  <DinamicDecorativeInputField
                    title={"JKM (Jaminan Kematian)"}
                    value={c?.jkm}
                    onChange={(_, newVal) => {
                      handleBPUChange(d?.id, newVal || 0, "jkm", c?.cityCode);
                    }}
                    adornment="Rp"
                    type="currency"
                    disabled={!c?.isApplied}
                    preventDelete
                  />
                  <DinamicDecorativeInputField
                    title={"JHT (Jaminan Hari Tua)"}
                    value={c?.jht}
                    onChange={(_, newVal) => {
                      handleBPUChange(d?.id, newVal || 0, "jht", c?.cityCode);
                    }}
                    adornment="Rp"
                    type="currency"
                    disabled={!c?.isApplied}
                    preventDelete
                  />
                </div>
              </div>
            ) : null}
          </div>
        ),
      },

      {
        title: "Expected Date",
        component: (
          <div id="date">
            <InputDateField
              title={"Expected Fulfillment Date"}
              value={c?.expectedDate}
              setValue={(newVal) => setC("expectedDate", newVal)}
              isError={
                alertMessage?.find(
                  (el) =>
                    el?.remark === "date" &&
                    el?.cityId === c?.id &&
                    el?.positionId === d?.id
                ) && !c?.expectedDate
              }
              isErrorV2
              errorText="Expected fulfillment date belum diisi"
              placeholder={"Select date"}
              preventPastDates
              disabled={!c?.isApplied}
            />
          </div>
        ),
      },
    ];
  }, [
    alertMessage,
    allowanceOptions,
    c?.allowances,
    c?.expectedDate,
    c?.isApplied,
    c?.jht,
    c?.jkk,
    c?.jkm,
    c?.jht1,
    c?.jkk1,
    c?.jkm1,
    c?.personalEquipments,
    c?.salaryDenom,
    c?.salaryValue,
    personalEquipmentOptions,
    selectedValueBPU,
    setC,
    // BPURangeSalary,
    handleBPUChange,
    handleSalaryChange,
    c?.cityCode,
    d?.id,
    c?.id,
  ]);

  return (
    <div
      className={`${Styles.cityData} ${
        Styles[isActive ? "visible" : "hidden"]
      }`}
    >
      <div className={Styles.cHeader}>
        {!c?.positionID && (
          <Switch
            isChecked={c?.isApplied}
            setIsChecked={(newVal) => setC("isApplied", newVal)}
            width="36px"
            height="20px"
          />
        )}
        <span>{c?.cityName}</span>
        <button onClick={() => setShowDelegations(true)}>
          <Icon icon={"eye-visible"} size={20} />
          <span>Lihat Deployment</span>
        </button>
      </div>
      <div className={Styles.cContent}>
        <div className={Styles.amount}>
          <span>Jumlah Pemenuhan</span>
          <Counter
            value={c?.amount}
            onSubtract={() => {
              setC("amount", +c?.amount - 1);
            }}
            onAdd={() => {
              setC("amount", +c?.amount + 1);
            }}
            setValue={(newVal) => {
              setC("amount", +newVal);
            }}
            disabled={!c?.isApplied}
          />
        </div>
        <div className={Styles.form}>
          {selectedButtonBPJS?.toUpperCase()?.includes("BPU")
            ? template?.map((t, tI) => (
                <div
                  key={tI}
                  className={`${Styles.fEach} ${
                    tI === template?.length - 1 && Styles.noBorder
                  }`}
                >
                  <div className={Styles.fEHeader}>
                    <span>{t?.title}</span>
                    {t?.extraTitle || <></>}
                  </div>
                  {t?.component}
                </div>
              ))
            : template
                ?.filter((obj) => !obj?.title?.includes("BPJS"))
                ?.map((t, tI) => (
                  <div key={tI} className={Styles.fEach}>
                    <div className={Styles.fEHeader}>
                      <span>{t?.title}</span>
                      {t?.extraTitle || <></>}
                    </div>
                    {t?.component}
                  </div>
                ))}
        </div>
      </div>
      {showDelegations && (
        <DelegationsModal
          show={showDelegations}
          delegationsForm={delegationsForm}
          setDelegationsForm={setDelegationsForm}
          onClose={() => setShowDelegations(false)}
          data={cityDeployments}
        />
      )}
    </div>
  );
};
