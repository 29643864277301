import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import PinMaps from "@Atom/Maps";
import Images from "@Theme/Images";
import React, { useCallback, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Spinner from "@Atom/Spinner";
import Stepper from "@Molecule/Stepper";
import moment from "moment";

export default function SignPreview({
  data,
  setStep,
  pdf,
  images,
  handleSubmit,
  isSubmitting,
  error,
  isExtend = false,
  dataStepOne,
  familyMembers,
  educationHistory,
  jobHistory,
  performanceHistory,
  documents,
}) {
  // eslint-disable-next-line no-unused-vars
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const maxLength = 32;

  const [selectedButton, setSelectedButton] = useState("Dokumentasi Kontrak");
  const [currentStep, setCurrentStep] = useState(1);

  const optionButtons = useMemo(() => {
    return [
      {
        id: 1,
        name: "Dokumentasi Kontrak",
      },
      {
        id: 2,
        name: "Data Diri",
      },
    ];
  }, []);

  const RenderPdf = useCallback(() => {
    if (windowSize[0] > 768) {
      if (pdf) {
        return (
          <div className={Styles.PdfWrapper}>
            <embed src={`${pdf}#navpanes=0`} type="application/pdf"></embed>
          </div>
        );
      }
    } else {
      return null;
    }
  }, [windowSize, pdf]);

  const stepsOptions = useMemo(() => {
    return [
      {
        id: 1,
        label: "Personal Data",
        isDone: false,
      },
      {
        id: 2,
        label: "Data Keluarga",
        isDone: false,
      },
      {
        id: 3,
        label: "Data Pendidikan",
        isDone: false,
      },
      {
        id: 4,
        label: "Data Pekerjaan",
        isDone: false,
      },
      {
        id: 5,
        label: "Data Prestasi",
        isDone: false,
      },
      {
        id: 6,
        label: "Unggah Dokumen",
        isDone: false,
      },
    ];
  }, []);

  const handleClickStep = (step) => {
    if (+step === 1 || +currentStep > +step) {
      setCurrentStep(step);
    } else if (+currentStep < +step) {
      setCurrentStep(step);
    }
  };

  const contentPersonalData = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <div className={Styles.personalDataBox}>
            <div>
              <span>Personal Data</span>
              <div>
                <span>Nama</span>
                <span>{dataStepOne?.name || "-"}</span>
              </div>
              <div>
                <span>Tempat lahir</span>
                <span>{dataStepOne?.birthPlace || "-"}</span>
              </div>
              <div>
                <span>Tanggal lahir</span>
                <span>
                  {moment(new Date(dataStepOne?.birthDateTemp)).format(
                    "DD MMMM YYYY"
                  ) || "-"}
                </span>
              </div>

              <div>
                <span>Jenis kelamin</span>
                <span>{dataStepOne?.gender || "-"}</span>
              </div>
              <div>
                <span>Agama</span>
                <span>{dataStepOne?.religion || "-"}</span>
              </div>
              <div>
                <span>Golongan darah</span>
                <span>{dataStepOne?.bloodType || "-"}</span>
              </div>
              <div>
                <span>Tinggi badan</span>
                <span>{dataStepOne?.height || "-"}</span>
              </div>
              <div>
                <span>Berat badan</span>
                <span>{dataStepOne?.weight || "-"}</span>
              </div>
              <div>
                <span>Status pernikahan</span>
                <span>{dataStepOne?.maritalStatus || "-"}</span>
              </div>
              <div>
                <span>No. KTP</span>
                <span>{dataStepOne?.NIK || "-"}</span>
              </div>
              <div>
                <span>No. Kartu Keluarga</span>
                <span>{dataStepOne?.KK || "-"}</span>
              </div>
              <div>
                <span>Masa berlaku</span>
                <span>
                  {dataStepOne?.lifetimeIdCard
                    ? "Seumur Hidup"
                    : dataStepOne?.idCardPeriodTemp
                    ? moment(new Date(dataStepOne?.idCardPeriodTemp)).format(
                        "DD MMMM YYYY"
                      )
                    : "-"}
                </span>
              </div>

              <div>
                <span>NPWP</span>
                <span>
                  {dataStepOne?.noTaxNumber
                    ? "Tidak Memiliki"
                    : dataStepOne?.taxNumber || "-"}
                </span>
              </div>
              <div>
                <span>Email</span>
                <span>{dataStepOne?.email || "-"}</span>
              </div>
              <div>
                <span>No. Handphone</span>
                <span>62{dataStepOne?.phoneNumber || "-"}</span>
              </div>
              <div>
                <span>Nama ibu</span>
                <span>{dataStepOne?.biologicalMotherName || "-"}</span>
              </div>
            </div>

            <div>
              <span>Alamat KTP</span>
              <div>
                <span>Alamat</span>
                <span>{dataStepOne?.idCardAddress || "-"}</span>
              </div>
              <div>
                <span>RT</span>
                <span>{dataStepOne?.idCardRt || "-"}</span>
              </div>
              <div>
                <span>RW</span>
                <span>{dataStepOne?.idCardRw || "-"}</span>
              </div>
              <div>
                <span>Kelurahan</span>
                <span>{dataStepOne?.idCardWard || "-"}</span>
              </div>
              <div>
                <span>Kecamatan</span>
                <span>{dataStepOne?.idCardSubdistrict || "-"}</span>
              </div>
              <div>
                <span>Kota</span>
                <span>{dataStepOne?.idCardRegencyTemp || "-"}</span>
              </div>
              <div>
                <span>Provinsi</span>
                <span>{dataStepOne?.idCardProvinceTemp || "-"}</span>
              </div>
              <div>
                <span>Kode POS</span>
                <span>{dataStepOne?.idCardPostal || "-"}</span>
              </div>
            </div>

            <div>
              <span>Alamat Domisili</span>
              <div>
                <span>Alamat</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardAddress || "-"
                    : dataStepOne?.domicileAddress || "-"}
                </span>
              </div>
              <div>
                <span>RT</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardRt || "-"
                    : dataStepOne?.domicileRt || "-"}
                </span>
              </div>
              <div>
                <span>RW</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardRw || "-"
                    : dataStepOne?.domicileRw || "-"}
                </span>
              </div>
              <div>
                <span>Kelurahan</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardWard || "-"
                    : dataStepOne?.domicileWard || "-"}
                </span>
              </div>
              <div>
                <span>Kecamatan</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardSubdistrict || "-"
                    : dataStepOne?.domicileSubdistrict || "-"}
                </span>
              </div>
              <div>
                <span>Kota</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardRegencyTemp || "-"
                    : dataStepOne?.domicileRegencyTemp || "-"}
                </span>
              </div>
              <div>
                <span>Provinsi</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardProvinceTemp || "-"
                    : dataStepOne?.domicileProvinceTemp || "-"}
                </span>
              </div>
              <div>
                <span>Kode POS</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardPostal || "-"
                    : dataStepOne?.domicilePostal || "-"}
                </span>
              </div>
            </div>
            <div>
              <span>Pendidikan Terakhir</span>
              <div>
                <span>Pendidikan terakhir</span>
                <span>{dataStepOne?.educationTemp || "-"}</span>
              </div>
              <div>
                <span>Nama Sekolah / Universitas</span>
                <span>{dataStepOne?.institution || "-"}</span>
              </div>
              <div>
                <span>Fakultas / Jurusan</span>
                <span>{dataStepOne?.faculty || "-"}</span>
              </div>
              <div>
                <span>IPK</span>
                <span>{dataStepOne?.gpa || "-"}</span>
              </div>
            </div>

            <div>
              <span>Kontak Darurat</span>
              <div>
                <span>Nama</span>
                <span>{dataStepOne?.emergencyContactName || "-"}</span>
              </div>
              <div>
                <span>Hubungan Kontak Darurat</span>
                <span>
                  {dataStepOne?.emergencyContactRelationshipTemp || "-"}
                </span>
              </div>
              <div>
                <span>Telp / HP Kontak Darurat</span>
                <span>62{dataStepOne?.emergencyPhoneNumber || "-"}</span>
              </div>
              <div>
                <span>Alamat</span>
                <span>{dataStepOne?.emergencyAddress || "-"}</span>
              </div>
            </div>

            <div>
              <span>Kontak Keluarga Serumah</span>
              <div>
                <span>Nama</span>
                <span>{dataStepOne?.sameHouseFamilyContactName || "-"}</span>
              </div>
              <div>
                <span>Hubungan Kontak Serumah</span>
                <span>
                  {dataStepOne?.sameHouseFamilyContactRelationshipTemp || "-"}
                </span>
              </div>
              <div>
                <span>Telp / HP Kontak Serumah</span>
                <span>
                  62{dataStepOne?.sameHouseFamilyContactPhoneNumber || "-"}
                </span>
              </div>
              <div>
                <span>Alamat</span>
                <span>{dataStepOne?.sameHouseFamilyContactAddress || "-"}</span>
              </div>
            </div>

            <div>
              <span>Kontak Keluarga Tidak Serumah</span>
              <div>
                <span>Nama</span>
                <span>
                  {dataStepOne?.differentHouseFamilyContactName || "-"}
                </span>
              </div>
              <div>
                <span>Hubungan Kontak Tidak Serumah</span>
                <span>
                  {dataStepOne?.differentHouseFamilyContactRelationshipTemp ||
                    "-"}
                </span>
              </div>
              <div>
                <span>Telp / HP Kontak Tidak Serumah</span>
                <span>
                  62{dataStepOne?.differentHouseFamilyContactPhoneNumber || "-"}
                </span>
              </div>
              <div>
                <span>Alamat</span>
                <span>
                  {dataStepOne?.differentHouseFamilyContactAddress || "-"}
                </span>
              </div>
            </div>

            <div>
              <span>Data Lainnya</span>
              <div>
                <span>Nama bank</span>
                <span>{dataStepOne?.bankNameTemp || "-"}</span>
              </div>
              <div>
                <span>No. Rekening</span>
                <span>{dataStepOne?.bankAccountNumber || "-"}</span>
              </div>
              <div>
                <span>Klinik / Puskemas terdekat</span>
                <span>{dataStepOne?.nearestHealthFacility || "-"}</span>
              </div>
            </div>

            <div>
              <span>Sosial Media</span>
              <div>
                <span>Facebook</span>
                <span>{dataStepOne?.fbUsername || "-"}</span>
              </div>
              <div>
                <span>X</span>
                <span>{dataStepOne?.twitterUsername || "-"}</span>
              </div>
              <div>
                <span>Instagram</span>
                <span>{dataStepOne?.igUsername || "-"}</span>
              </div>
              <div>
                <span>Linkedin</span>
                <span>{dataStepOne?.linkedinUsername || "-"}</span>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={Styles.personalDataBox}>
            {familyMembers?.length > 0
              ? familyMembers?.map((el, i) => {
                  return (
                    <div key={i}>
                      <span>
                        {i === 0 ? "Data Diri Anda" : `Data Keluarga ${i}`}
                      </span>
                      <div>
                        <span>Nama</span>
                        <span>{el?.name || "-"}</span>
                      </div>
                      <div>
                        <span>Tempat lahir</span>
                        <span>{el?.birthPlace || "-"}</span>
                      </div>
                      <div>
                        <span>Tanggal lahir</span>
                        <span>
                          {el?.birthDate
                            ? moment(new Date(el?.birthDate)).format(
                                "DD MMMM YYYY"
                              )
                            : "-"}
                        </span>
                      </div>
                      <div>
                        <span>Tanggal lahir</span>
                        <span>
                          {el?.birthDate
                            ? moment(new Date(el?.birthDate)).format(
                                "DD MMMM YYYY"
                              )
                            : "-"}
                        </span>
                      </div>

                      <div>
                        <span>NIK</span>
                        <span>{el?.nik || "-"}</span>
                      </div>
                      <div>
                        <span>Jenis kelamin</span>
                        <span>{el?.gender || "-"}</span>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        );
      case 3:
        return (
          <div className={Styles.personalDataBox}>
            {educationHistory?.length > 0
              ? educationHistory?.map((el, i) => {
                  return (
                    <div key={i}>
                      <span>Data Pendidikan {i + 1}</span>
                      <div>
                        <span>Jenis Pendidikan</span>
                        <span>{el?.type || "-"}</span>
                      </div>
                      <div>
                        <span>Dari Tahun</span>
                        <span>{el?.startYear || "-"}</span>
                      </div>
                      <div>
                        <span>Sampai Tahun</span>
                        <span>{el?.endYear || "-"}</span>
                      </div>
                      <div>
                        <span>Nama Penyelenggara</span>
                        <span>{el?.name || "-"}</span>
                      </div>
                      <div>
                        <span>Fakultas/Jurusan</span>
                        <span>{el?.mastery || "-"}</span>
                      </div>
                      <div>
                        <span>Kota</span>
                        <span>{el?.city || "-"}</span>
                      </div>
                      <div>
                        <span>Nomor Ijazah</span>
                        <span>{el?.sertificateNumber || "-"}</span>
                      </div>
                    </div>
                  );
                })
              : null}
            <div></div>
          </div>
        );

      case 4:
        return (
          <div className={Styles.personalDataBox}>
            {jobHistory?.length > 0 ? (
              jobHistory?.map((el, i) => {
                return (
                  <div key={i}>
                    <span>Data Pekerjaan {i + 1}</span>
                    <div>
                      <span>Dari Tahun</span>
                      <span>{el?.startYear || "-"}</span>
                    </div>
                    <div>
                      <span>Sampai Tahun</span>
                      <span>{el?.endYear || "-"}</span>
                    </div>
                    <div>
                      <span>Nama Perusahaan</span>
                      <span>{el?.companyName || "-"}</span>
                    </div>
                    <div>
                      <span>Jabatan</span>
                      <span>{el?.position || "-"}</span>
                    </div>
                    <div>
                      <span>Deskripsi</span>
                      <span>{el?.remark || "-"}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={`${Styles.emptyHeader}`}>
                <span>Belum ada Data Pekerjaan Ditambahkan</span>
              </div>
            )}
          </div>
        );

      case 5:
        return (
          <div className={Styles.personalDataBox}>
            {performanceHistory?.length > 0 ? (
              performanceHistory?.map((el, i) => {
                return (
                  <div key={i}>
                    <span>Data Prestasi {i + 1}</span>
                    <div>
                      <span>Tahun</span>
                      <span>{el?.year || "-"}</span>
                    </div>
                    <div>
                      <span>Prestasi</span>
                      <span>{el?.achievement || "-"}</span>
                    </div>
                    <div>
                      <span>Keterangan</span>
                      <span>{el?.remark || "-"}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={`${Styles.emptyHeader}`}>
                <span>Belum ada Data Prestasi Ditambahkan</span>
              </div>
            )}
          </div>
        );

      case 6:
        return (
          <div className={Styles.documentBox}>
            <div>
              <span>Dokumen Wajib</span>
              <div>
                {documents?.mandatory?.map((el, i) => {
                  return (
                    <div key={i}>
                      {el?.preview ? (
                        <div className={Styles.imagesBox}>
                          <img src={el?.preview} alt="preview" />
                        </div>
                      ) : (
                        <div className={Styles.imagesNull}>
                          <Icon
                            icon={el?.title === "CV" ? "pdf-type" : "jpg-type"}
                            size={24}
                            color={"#1571DE"}
                          />
                        </div>
                      )}

                      <div className={Styles.desc}>
                        <span>{el?.title || "-"}</span>
                        <span>
                          {el?.name
                            ? el?.name?.length > maxLength
                              ? el?.name?.substring(0, maxLength) + "..."
                              : el?.name
                            : "Foto tidak ditemukan"}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              <span>Dokumen Tidak Wajib</span>
              <div>
                {documents?.nonMandatory?.map((el, i) => {
                  return (
                    <div key={i}>
                      {el?.preview ? (
                        <div className={Styles.imagesBox}>
                          <img src={el?.preview} alt="preview" />
                        </div>
                      ) : (
                        <div className={Styles.imagesNull}>
                          <Icon
                            icon={el?.title === "CV" ? "pdf-type" : "jpg-type"}
                            size={24}
                            color={"#1571DE"}
                          />
                        </div>
                      )}

                      <div className={Styles.desc}>
                        <span>{el?.title || "-"}</span>
                        <span>
                          {" "}
                          {el?.name
                            ? el?.name?.length > maxLength
                              ? el?.name?.substring(0, maxLength) + "..."
                              : el?.name
                            : "Foto tidak ditemukan"}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {documents?.additional?.length > 0 ? (
              <div>
                <span>Dokumen Tambahan</span>
                <div>
                  {documents?.additional?.map((el, i) => {
                    return (
                      <div key={i}>
                        {el?.preview ? (
                          <div className={Styles.imagesBox}>
                            <img src={el?.preview} alt="preview" />
                          </div>
                        ) : (
                          <div className={Styles.imagesNull}>
                            <Icon
                              icon={
                                el?.title === "CV" ? "pdf-type" : "jpg-type"
                              }
                              size={24}
                              color={"#1571DE"}
                            />
                          </div>
                        )}

                        <div className={Styles.desc}>
                          <span>{el?.title || "-"}</span>
                          <span>
                            {" "}
                            {el?.name
                              ? el?.name?.length > maxLength
                                ? el?.name?.substring(0, maxLength) + "..."
                                : el?.name
                              : "Foto tidak ditemukan"}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        );
      default:
        return (
          <div className={Styles.personalDataBox}>
            <div>
              <span>Personal Data</span>
              <div>
                <span>Nama</span>
                <span>{dataStepOne?.name || "-"}</span>
              </div>
              <div>
                <span>Tempat lahir</span>
                <span>{dataStepOne?.birthPlace || "-"}</span>
              </div>
              <div>
                <span>Tanggal lahir</span>
                <span>
                  {moment(new Date(dataStepOne?.birthDateTemp)).format(
                    "DD MMMM YYYY"
                  ) || "-"}
                </span>
              </div>

              <div>
                <span>Jenis kelamin</span>
                <span>{dataStepOne?.gender || "-"}</span>
              </div>
              <div>
                <span>Agama</span>
                <span>{dataStepOne?.religion || "-"}</span>
              </div>
              <div>
                <span>Golongan darah</span>
                <span>{dataStepOne?.bloodType || "-"}</span>
              </div>
              <div>
                <span>Tinggi badan</span>
                <span>{dataStepOne?.height || "-"}</span>
              </div>
              <div>
                <span>Berat badan</span>
                <span>{dataStepOne?.weight || "-"}</span>
              </div>
              <div>
                <span>Status pernikahan</span>
                <span>{dataStepOne?.maritalStatus || "-"}</span>
              </div>
              <div>
                <span>No. KTP</span>
                <span>{dataStepOne?.NIK || "-"}</span>
              </div>
              <div>
                <span>No. Kartu Keluarga</span>
                <span>{dataStepOne?.KK || "-"}</span>
              </div>
              <div>
                <span>Masa berlaku</span>
                <span>
                  {dataStepOne?.lifetimeIdCard
                    ? "Seumur Hidup"
                    : dataStepOne?.idCardPeriodTemp
                    ? moment(new Date(dataStepOne?.idCardPeriodTemp)).format(
                        "DD MMMM YYYY"
                      )
                    : "-"}
                </span>
              </div>

              <div>
                <span>NPWP</span>
                <span>
                  {dataStepOne?.noTaxNumber
                    ? "Tidak Memiliki"
                    : dataStepOne?.taxNumber || "-"}
                </span>
              </div>
              <div>
                <span>Email</span>
                <span>{dataStepOne?.email || "-"}</span>
              </div>
              <div>
                <span>No. Handphone</span>
                <span>62{dataStepOne?.phoneNumber || "-"}</span>
              </div>
              <div>
                <span>Nama ibu</span>
                <span>{dataStepOne?.biologicalMotherName || "-"}</span>
              </div>
            </div>

            <div>
              <span>Alamat KTP</span>
              <div>
                <span>Alamat</span>
                <span>{dataStepOne?.idCardAddress || "-"}</span>
              </div>
              <div>
                <span>RT</span>
                <span>{dataStepOne?.idCardRt || "-"}</span>
              </div>
              <div>
                <span>RW</span>
                <span>{dataStepOne?.idCardRw || "-"}</span>
              </div>
              <div>
                <span>Kelurahan</span>
                <span>{dataStepOne?.idCardWard || "-"}</span>
              </div>
              <div>
                <span>Kecamatan</span>
                <span>{dataStepOne?.idCardSubdistrict || "-"}</span>
              </div>
              <div>
                <span>Kota</span>
                <span>{dataStepOne?.idCardRegencyTemp || "-"}</span>
              </div>
              <div>
                <span>Provinsi</span>
                <span>{dataStepOne?.idCardProvinceTemp || "-"}</span>
              </div>
              <div>
                <span>Kode POS</span>
                <span>{dataStepOne?.idCardPostal || "-"}</span>
              </div>
            </div>

            <div>
              <span>Alamat Domisili</span>
              <div>
                <span>Alamat</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardAddress || "-"
                    : dataStepOne?.domicileAddress || "-"}
                </span>
              </div>
              <div>
                <span>RT</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardRt || "-"
                    : dataStepOne?.domicileRt || "-"}
                </span>
              </div>
              <div>
                <span>RW</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardRw || "-"
                    : dataStepOne?.domicileRw || "-"}
                </span>
              </div>
              <div>
                <span>Kelurahan</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardWard || "-"
                    : dataStepOne?.domicileWard || "-"}
                </span>
              </div>
              <div>
                <span>Kecamatan</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardSubdistrict || "-"
                    : dataStepOne?.domicileSubdistrict || "-"}
                </span>
              </div>
              <div>
                <span>Kota</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardRegencyTemp || "-"
                    : dataStepOne?.domicileRegencyTemp || "-"}
                </span>
              </div>
              <div>
                <span>Provinsi</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardProvinceTemp || "-"
                    : dataStepOne?.domicileProvinceTemp || "-"}
                </span>
              </div>
              <div>
                <span>Kode POS</span>
                <span>
                  {dataStepOne?.isDomicileTheSame
                    ? dataStepOne?.idCardPostal || "-"
                    : dataStepOne?.domicilePostal || "-"}
                </span>
              </div>
            </div>
            <div>
              <span>Pendidikan Terakhir</span>
              <div>
                <span>Pendidikan terakhir</span>
                <span>{dataStepOne?.educationTemp || "-"}</span>
              </div>
              <div>
                <span>Nama Sekolah / Universitas</span>
                <span>{dataStepOne?.institution || "-"}</span>
              </div>
              <div>
                <span>Fakultas / Jurusan</span>
                <span>{dataStepOne?.faculty || "-"}</span>
              </div>
              <div>
                <span>IPK</span>
                <span>{dataStepOne?.gpa || "-"}</span>
              </div>
            </div>

            <div>
              <span>Kontak Darurat</span>
              <div>
                <span>Nama</span>
                <span>{dataStepOne?.emergencyContactName || "-"}</span>
              </div>
              <div>
                <span>Hubungan Kontak Darurat</span>
                <span>
                  {dataStepOne?.emergencyContactRelationshipTemp || "-"}
                </span>
              </div>
              <div>
                <span>Telp / HP Kontak Darurat</span>
                <span>62{dataStepOne?.emergencyPhoneNumber || "-"}</span>
              </div>
              <div>
                <span>Alamat</span>
                <span>{dataStepOne?.emergencyAddress || "-"}</span>
              </div>
            </div>

            <div>
              <span>Kontak Keluarga Serumah</span>
              <div>
                <span>Nama</span>
                <span>{dataStepOne?.sameHouseFamilyContactName || "-"}</span>
              </div>
              <div>
                <span>Hubungan Kontak Serumah</span>
                <span>
                  {dataStepOne?.sameHouseFamilyContactRelationshipTemp || "-"}
                </span>
              </div>
              <div>
                <span>Telp / HP Kontak Serumah</span>
                <span>
                  62{dataStepOne?.sameHouseFamilyContactPhoneNumber || "-"}
                </span>
              </div>
              <div>
                <span>Alamat</span>
                <span>{dataStepOne?.sameHouseFamilyContactAddress || "-"}</span>
              </div>
            </div>

            <div>
              <span>Kontak Keluarga Tidak Serumah</span>
              <div>
                <span>Nama</span>
                <span>
                  {dataStepOne?.differentHouseFamilyContactName || "-"}
                </span>
              </div>
              <div>
                <span>Hubungan Kontak Tidak Serumah</span>
                <span>
                  {dataStepOne?.differentHouseFamilyContactRelationshipTemp ||
                    "-"}
                </span>
              </div>
              <div>
                <span>Telp / HP Kontak Tidak Serumah</span>
                <span>
                  62{dataStepOne?.differentHouseFamilyContactPhoneNumber || "-"}
                </span>
              </div>
              <div>
                <span>Alamat</span>
                <span>
                  {dataStepOne?.differentHouseFamilyContactAddress || "-"}
                </span>
              </div>
            </div>

            <div>
              <span>Data Lainnya</span>
              <div>
                <span>Nama bank</span>
                <span>{dataStepOne?.bankNameTemp || "-"}</span>
              </div>
              <div>
                <span>No. Rekening</span>
                <span>{dataStepOne?.bankAccountNumber || "-"}</span>
              </div>
              <div>
                <span>Klinik / Puskemas terdekat</span>
                <span>{dataStepOne?.nearestHealthFacility || "-"}</span>
              </div>
            </div>

            <div>
              <span>Sosial Media</span>
              <div>
                <span>Facebook</span>
                <span>{dataStepOne?.fbUsername || "-"}</span>
              </div>
              <div>
                <span>X</span>
                <span>{dataStepOne?.twitterUsername || "-"}</span>
              </div>
              <div>
                <span>Instagram</span>
                <span>{dataStepOne?.igUsername || "-"}</span>
              </div>
              <div>
                <span>Linkedin</span>
                <span>{dataStepOne?.linkedinUsername || "-"}</span>
              </div>
            </div>
          </div>
        );
    }
  }, [
    currentStep,
    dataStepOne,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    maxLength,
  ]);

  const content = useMemo(() => {
    switch (selectedButton) {
      case "Dokumentasi Kontrak":
        return (
          <div className={Styles.SectionWrapper}>
            <div className={Styles.SignContainer}>
              <div className={Styles.SignWrapper}>
                <p className={Styles.SectionTitle}>Tanda Tangan</p>
                <div className={Styles.CanvasWrapper}>
                  <img src={data.signature} alt="sign" />
                </div>
              </div>
              <div className={Styles.SignWrapper}>
                <p className={Styles.SectionTitle}>Paraf</p>
                <div className={Styles.CanvasWrapper}>
                  <img src={data.initials} alt="initial" />
                </div>
              </div>
            </div>
            <div className={Styles.Section}>
              <p className={Styles.SectionTitle}>Foto Selfie</p>
              <div className={Styles.box}>
                <img src={URL.createObjectURL(data.image)} alt="id" />
                <div className={Styles.location}>
                  <span>Lokasi Foto Selfie</span>
                  <PinMaps
                    lat={data.latitudeImage}
                    lng={data.longitudeImage}
                    mapHeight={"278px"}
                    mapWidth={"100%"}
                  />
                  <div className={Styles.Coords}>
                    <span>Latitude : {data.latitudeImage}</span>
                    <span>Longitude : {data.longitudeImage}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.Section}>
              <p className={Styles.SectionTitle}>Foto KTP</p>
              <div className={Styles.box}>
                <img src={URL.createObjectURL(data.imagesIdCard)} alt="id" />
                <div className={Styles.location}>
                  <span>Lokasi Foto KTP</span>
                  <PinMaps
                    lat={data.latitudeImageIdCard}
                    lng={data.longitudeImageIdCard}
                    mapHeight={"278px"}
                    mapWidth={"100%"}
                  />
                  <div className={Styles.Coords}>
                    <span>Latitude : {data.latitudeImageIdCard}</span>
                    <span>Longitude : {data.longitudeImageIdCard}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={Styles.Section}>
  <p className={Styles.SectionTitle}>Lokasi</p>
  <PinMaps
    lat={data.latitude}
    lng={data.longitude}
    mapHeight={"278px"}
    mapWidth={"100%"}
  />
  <div className={Styles.Coords}>
    <p>Latitude : {data.latitude}</p>
    <p>Longitude : {data.longitude}</p>
  </div>
</div> */}
          </div>
        );
      case "Data Diri":
        return (
          <div className={Styles.SectionPersonalData}>
            {contentPersonalData}
          </div>
        );
      default:
        return (
          <div className={Styles.SectionWrapper}>
            <div className={Styles.SignContainer}>
              <div className={Styles.SignWrapper}>
                <p className={Styles.SectionTitle}>Tanda Tangan</p>
                <div className={Styles.CanvasWrapper}>
                  <img src={data.signature} alt="sign" />
                </div>
              </div>
              <div className={Styles.SignWrapper}>
                <p className={Styles.SectionTitle}>Paraf</p>
                <div className={Styles.CanvasWrapper}>
                  <img src={data.initials} alt="initial" />
                </div>
              </div>
            </div>
            <div className={Styles.Section}>
              <p className={Styles.SectionTitle}>Foto Selfie</p>
              <div className={Styles.box}>
                <img src={URL.createObjectURL(data.image)} alt="id" />
                <div className={Styles.location}>
                  <span>Lokasi Foto Selfie</span>
                  <PinMaps
                    lat={data.latitudeImage}
                    lng={data.longitudeImage}
                    mapHeight={"278px"}
                    mapWidth={"100%"}
                  />
                  <div className={Styles.Coords}>
                    <span>Latitude : {data.latitudeImage}</span>
                    <span>Longitude : {data.longitudeImage}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.Section}>
              <p className={Styles.SectionTitle}>Foto KTP</p>
              <div className={Styles.box}>
                <img src={URL.createObjectURL(data.imagesIdCard)} alt="id" />
                <div className={Styles.location}>
                  <span>Lokasi Foto KTP</span>
                  <PinMaps
                    lat={data.latitudeImageIdCard}
                    lng={data.longitudeImageIdCard}
                    mapHeight={"278px"}
                    mapWidth={"100%"}
                  />
                  <div className={Styles.Coords}>
                    <span>Latitude : {data.latitudeImageIdCard}</span>
                    <span>Longitude : {data.longitudeImageIdCard}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={Styles.Section}>
      <p className={Styles.SectionTitle}>Lokasi</p>
      <PinMaps
        lat={data.latitude}
        lng={data.longitude}
        mapHeight={"278px"}
        mapWidth={"100%"}
      />
      <div className={Styles.Coords}>
        <p>Latitude : {data.latitude}</p>
        <p>Longitude : {data.longitude}</p>
      </div>
    </div> */}
          </div>
        );
    }
  }, [selectedButton, data, contentPersonalData]);

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Headers}>
          <div className={Styles.Logo}>
            <div>
              <img src={Images.PERMATA_LOGO} alt="permata-logo" />
            </div>
            <p>Permata Indo Sejahtera</p>
          </div>
          <div>
            <p>Preview Kontrak</p>
          </div>
        </div>

        <div className={Styles.informationBox}>
          <div>
            <Icon icon={"information-solid"} size={16} color={"#1571DE"} />
          </div>
          <div>
            <span>Informasi Penting</span>
            <span>
              Jangan refresh halaman ini, data akan hilang jika Anda merefresh
              halaman ini
            </span>
          </div>
        </div>

        <div className={Styles.Body}>
          <div className={Styles.LeftSection}>
            <div
              className={`${Styles.HeaderSection} ${
                isExtend &&
                selectedButton === "Data Diri" &&
                Styles.headerExtend
              }`}
            >
              <div className={Styles.top}>
                <p className={Styles.HeaderTitle}>Data Anda</p>
                <div
                  className={Styles.ButtonWithIcon}
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  <p>Ubah Data</p>
                  <Icon icon="pencil-square" size={20} />
                </div>
              </div>
              {isExtend ? (
                <div className={Styles.selectedButton}>
                  {optionButtons?.map((el) => {
                    return (
                      <div
                        className={`${
                          selectedButton === el?.name && Styles.active
                        }`}
                        key={el?.id}
                        onClick={() => {
                          setSelectedButton(el?.name);
                        }}
                      >
                        {el?.name}
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {isExtend && selectedButton === "Data Diri" && (
                <div className={Styles.stepper}>
                  <Stepper
                    type="filled"
                    onClick={handleClickStep}
                    options={stepsOptions}
                    currentStep={currentStep}
                  />
                </div>
              )}
            </div>

            {content}
          </div>
          <div className={Styles.RightSection}>
            <RenderPdf />
          </div>
          {/* <div className={`${Styles.Action} ${Styles.ShowOnMobile}`}>
            <Button
              type={"button-icon"}
              title={"Setujui Kontrak"}
              icon={"arrow-up-right"}
              onClick={handleSubmit}
            />
          </div> */}
        </div>
      </div>
      <div className={Styles.actionBox}>
        {error && <span>{error}</span>}
        <div className={Styles.actionWrapper}>
          <span>
            Silahkan submit kontrak setelah anda selesai meninjau preview
            kontrak ini.
          </span>
          <Button
            type={"button-icon"}
            title={isSubmitting ? <Spinner /> : "Submit"}
            icon={"arrow-up-right"}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
}
