/* eslint-disable no-unused-vars */
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import LoadingAnimation from "@Atom/LoadingAnimation";
import ModalWrapper from "@Atom/ModalWrapper";
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import ContractViewModal from "@Molecule/_modal/ContractView";
import { setContractApproval } from "@Services/contract/contract";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Styles from "./style.module.scss";

const PDFJS = window.pdfjsLib;

export default function BmContractViewLayout({ data }) {
  const [modalType, setModalType] = useState("");
  const [pdf, setPdf] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [images, setImages] = useState([]);

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleCloseModal = () => {
    setModalType("");
  };

  useEffect(() => {
    const getPdfUrl = async () => {
      setLoadingPdf(true);
      const res = await convertHtmlToPdf(
        data.response.candidateContract?.template
      );
      setPdfUrl(res);
      setLoadingPdf(false);
    };
    getPdfUrl();
  }, [data.response.candidateContract?.template]);

  async function showPdf(url) {
    try {
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      });
      setPdf(_PDF_DOC);
    } catch (error) {
      alert(error.message);
    }
  }
  async function renderPage() {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
  }

  useEffect(() => {
    isMobile && pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  useEffect(() => {
    isMobile && pdfUrl && showPdf(pdfUrl);
  }, [pdfUrl]);

  const [errorStatus, setErrorStatus] = useState("");

  const handleSubmit = async (action, remark = "") => {
    try {
      const res = await setContractApproval(
        data.response.candidateContract.id,
        action,
        remark
      );
      if (res.code === 200) {
        if (action === "Accepted") {
          setModalType("success-approve");
        } else {
          setModalType("success-reject");
        }
      }
    } catch (error) {
      setErrorStatus(error?.response?.data?.error || "Something went wrong");
      console.log(error);
    }
  };

  const RenderActionButton = useCallback(() => {
    const res = data.response.candidate;

    if (
      (res.tag === "Invited" && res.stage === "SIGN-CONTRACT") ||
      res?.stage === "IOS-SUCCESS" ||
      res?.stage === "DONE"
    ) {
      return (
        <div className={Styles.DisabledButton}>
          <Button
            type={"button-icon"}
            title={"Approved"}
            icon={"check-circle"}
            // onClick={() => setModalType("confirm-approve")}
            disabled={true}
          />
        </div>
      );
    } else if (res.tag === "Declined") {
      return (
        <div className={`${Styles.secondaryButton} ${Styles.DisabledButton}`}>
          <div className={Styles.label}>Rejected</div>
          <Icon icon="cross-circle" size="20" className={Styles.icon} />
        </div>
      );
    } else {
      return (
        <div className={Styles.ActionSection}>
          <div
            className={Styles.secondaryButton}
            onClick={() => setModalType("confirm-reject")}
          >
            <div className={Styles.label}>Tolak Kontrak</div>
            <Icon icon="cross-circle" size="20" className={Styles.icon} />
          </div>
          <Button
            type={"button-icon"}
            title={"Setujui Kontrak"}
            icon={"check-circle"}
            onClick={() => setModalType("confirm-approve")}
          />
        </div>
      );
    }
  }, [data]);

  const RenderPdf = useCallback(() => {
    if (windowSize[0] > 768) {
      if (pdfUrl) {
        return (
          <div className={Styles.PdfWrapper}>
            <embed src={`${pdfUrl}#navpanes=0`} type="application/pdf"></embed>
          </div>
        );
      } else {
        return (
          <div className={Styles.PdfWrapper}>
            <div style={{ width: "250px", aspectRatio: 1 }}>
              <LoadingAnimation />
            </div>
          </div>
        );
      }
    } else {
      if (images.length > 0) {
        return (
          <div className={`${Styles.ImagesPdfWrapper}`}>
            {images.map((el) => (
              <img src={el} alt="pdf" />
            ))}
          </div>
        );
      } else {
        return (
          <div className={Styles.PdfWrapper}>
            <div style={{ width: "250px", aspectRatio: 1 }}>
              <LoadingAnimation />
            </div>
          </div>
        );
      }
    }
  }, [windowSize, images, pdfUrl]);

  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <div className={Styles.ProfileSection}>
          <p>Candidate Information</p>
          <div className={Styles.ProfileWrapper}>
            <div></div>
            <div className={Styles.Profile}>
              <p>{data.response.candidate.name}</p>
              <p>+62 {data.response.candidate.phoneNumber.slice(2)}</p>
            </div>
          </div>
        </div>
        <div className={Styles.TitleSection}>
          <p>Persetujuan Kontrak Kerja</p>
        </div>
        <div className={Styles.ActionSection}>
          <RenderActionButton />
        </div>
      </div>
      {/* {loadingPdf ? (
        <div className={Styles.PdfContainer}>
          <div style={{ width: "250px", aspectRatio: 1 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.PdfContainer}>
          <embed src={`${pdf}#navpanes=0`} type="application/pdf"></embed>
        </div>
      )} */}
      <RenderPdf />
      {modalType && (
        <ModalWrapper show={Boolean(modalType)} forceCenter={true}>
          <ContractViewModal
            type={modalType}
            handleCloseModal={handleCloseModal}
            handleSubmit={(action, remark) => handleSubmit(action, remark)}
            errorStatus={errorStatus}
          />
        </ModalWrapper>
      )}
    </div>
  );
}
