import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import { useMemo, useRef } from "react";
// import { useDropzone } from "react-dropzone";
import SignaturePad from "react-signature-canvas";
import Styles from "../style.module.scss";
// import Spinner from "@Atom/Spinner";
import ImagesCard from "./Images";

export default function SignContract({
  state,
  setState,
  setStep,
  isExtend = false,
}) {
  const sigCanvas = useRef({});
  const initialCanvas = useRef({});

  const handleChangeState = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const clearSign = () => {
    if (state.signature) {
      handleChangeState("signature", "");
    } else {
      sigCanvas.current.clear();
    }
  };

  const clearInitial = () => {
    if (state.initials) {
      handleChangeState("initials", "");
    } else {
      initialCanvas.current.clear();
    }
  };

  const saveSign = () =>
    handleChangeState(
      "signature",
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );

  const saveInitials = () =>
    handleChangeState(
      "initials",
      initialCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );

  const disableButton = useMemo(
    () =>
      state.image === "" ||
      state.longitude === 0 ||
      state.latitude === 0 ||
      state.initials === "" ||
      state.signature === "" ||
      state?.longitudeImage === 0 ||
      state?.latitudeImage === 0 ||
      state?.imagesIdCard === "" ||
      state?.longitudeImageIdCard === 0 ||
      state?.latitudeImageIdCard === 0 ||
      state?.addressImage === "" ||
      state?.addressImageIdCard === "",
    [state]
  );

  return (
    <div
      className={`${Styles.ContractSignContainer} ${isExtend && Styles.width}`}
    >
      {isExtend ? null : (
        <div className={Styles.Header}>
          <img src={Images.KERJA365_LIGHT} alt="" />
          <div className={Styles.Logo}>
            <div>
              <img src={Images.PERMATA_LOGO} alt="permata-logo" />
            </div>
            <div>
              <span>Powered by</span>
              <p>Permata Indo Sejahtera</p>
            </div>
          </div>
        </div>
      )}

      {isExtend ? null : (
        <div className={Styles.informationBox}>
          <div>
            <Icon icon={"information-solid"} size={16} color={"#1571DE"} />
          </div>
          <div>
            <span>Informasi Penting</span>
            <span>
              Jangan refresh halaman ini, data akan hilang jika Anda merefresh
              halaman ini
            </span>
          </div>
        </div>
      )}

      <div className={Styles.SectionWrapper}>
        <div className={Styles.SignCanvasWrapper}>
          <p>Tanda Tangan</p>
          {state.signature ? (
            <div className={Styles.Canvas}>
              <div className={Styles.CanvasImage}>
                <img src={state.signature} alt="sign" />
              </div>
              <p className={Styles.CanvasText}>
                Tanda tangan harus sesui dengan KTP
              </p>
              <div className={Styles.CanvasClear}>
                <Icon icon="edit" size={24} onClick={clearSign} />
              </div>
            </div>
          ) : (
            <div className={Styles.Canvas}>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{ className: Styles.SignCanvas }}
              />
              <p className={Styles.CanvasText}>
                Tanda tangan harus sesui dengan KTP
              </p>
              <div className={Styles.CanvasAction}>
                <Icon icon="eraser" size={24} onClick={clearSign} />
                <Button title={"Submit"} type="primary" onClick={saveSign} />
              </div>
            </div>
          )}
        </div>
        <div className={Styles.SignCanvasWrapper}>
          <p>Paraf</p>
          {state.initials ? (
            <div className={Styles.Canvas}>
              <div className={Styles.CanvasImage}>
                <img src={state.initials} alt="sign" />
              </div>
              <div className={Styles.CanvasClear}>
                <Icon icon="edit" size={24} onClick={clearInitial} />
              </div>
            </div>
          ) : (
            <div className={Styles.Canvas}>
              <SignaturePad
                ref={initialCanvas}
                canvasProps={{ className: Styles.SignCanvas }}
              />
              <div className={Styles.CanvasAction}>
                <Icon icon="eraser" size={24} onClick={clearInitial} />
                <Button
                  title={"Submit"}
                  type="primary"
                  onClick={saveInitials}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.SectionWrapper}>
        <div className={Styles.TitleSection}>
          <div>Ambil Foto</div>
          <span>Foto Selfie</span>
        </div>

        <div />
        <ImagesCard state={state} setState={setState} />

        <div>
          <div className={`${Styles.Section} ${Styles.noPadding}`}>
            <img src={Images.TUTORIAL_SIGN_SELFIE} alt="example" />
          </div>
          <p className={Styles.Description}>Contoh foto selfie yang benar</p>
        </div>
      </div>

      <div className={Styles.SectionWrapper}>
        <div className={Styles.TitleSection}>
          {/* <div>Ambil Foto</div> */}
          <span>Foto KTP</span>
        </div>

        <div />

        <ImagesCard state={state} setState={setState} isKTP />
        <div>
          <div className={`${Styles.Section} ${Styles.noPadding}`}>
            <img src={Images.TUTORIAL_SIGN_IDCARD} alt="example" />
          </div>
          <p className={Styles.Description}>
            Foto KTP dengan memegang KTP di tangan anda
          </p>
        </div>
      </div>
      {isExtend ? null : (
        <div className={`${Styles.NonBreak}`} style={{ height: "40px" }}>
          <Button
            title={"Kembali"}
            type="secondary-blue"
            onClick={() => setStep(2)}
          />
          <Button
            title={"Preview"}
            type="primary"
            onClick={() => {
              setStep(4);
            }}
            disabled={disableButton}
          />
        </div>
      )}
    </div>
  );
}
