import Styles from './style.module.scss';
import ModalWrapper from '@Atom/ModalWrapper';
import Icon from '@Atom/Icon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { fileBaseUrl } from '@Config/api';
import Images from '@Theme/Images';
import OutlinedSearchBar from '@Atom/OutlinedSearchBar';

const RequestApprovalExtendContractBmModal = ({
    bmList,
    selectedBM,
    setSelectedBM,
    showModal,
    submit = () => {},
    onClickBack = () => {},
}) => {
    const [editBM, setEditBM] = useState(false);
    const [searchBM, setSearchBM] = useState('');

    console.log('BMSKE', selectedBM)
    
    const modalEditBMRef = useRef(null);

    const handleClickOutside = (event) => {
        if (modalEditBMRef.current && !modalEditBMRef.current.contains(event.target)) {
            setEditBM(false);
        }
    };

    useEffect(() => {
        if (editBM) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editBM]);

    const bmProfile = useMemo(() => {
        return bmList?.find((f) => f?.employeeId === selectedBM);
    }, [selectedBM, bmList]);

    const filteredBMList = useMemo(() => {
        return bmList?.filter((f) => f?.name?.toLowerCase().includes(searchBM?.toLowerCase()));
    }, [bmList, searchBM]);

    const handleImageError = (event) => {
        event.target.src = Images.AVA_DEFAULT; // Replace with default image
    };

    return (
        <ModalWrapper show={showModal} forceCenter>
            <div className={Styles.container}>
                <div className={Styles.header}>
                    <div className={Styles.imageWrapper}>
                        <Icon icon="send-solid" size="32px" />
                    </div>
                </div>
                <div className={Styles.textWrapper}>
                    <div className={Styles.title}>Request BM Approval</div>
                    <div className={Styles.text}>Approval kontrak akan dikirimkan kepada:</div>
                </div>
                <div className={Styles.selectBMBox}>
                    <div className={Styles.selectBMWrapper} ref={modalEditBMRef}>
                        <div className={Styles.stackedProfile}>
                            <img
                                style={{ width: '26px' }}
                                src={
                                    !bmProfile?.profilePicture
                                        ? Images?.AVA_DEFAULT
                                        : bmProfile?.profilePicture?.includes('http')
                                        ? bmProfile?.profilePicture
                                        : fileBaseUrl + bmProfile?.profilePicture
                                }
                                alt={bmProfile?.name}
                                onError={handleImageError}
                            />
                            <span>{bmProfile?.name}</span>
                        </div>
                        <div
                            className={Styles.buttonIcon}
                            onClick={() => {
                                setEditBM(!editBM);
                            }}>
                            <Icon icon="write" size="20px" color="#1571DE" />
                        </div>
                        {editBM && (
                            <div className={Styles.popEditBMWrapper}>
                                <div className={Styles.searchWrapper}>
                                    <OutlinedSearchBar 
                                      placeholder={'Cari business manager'} 
                                      value={searchBM}
                                      setValue={setSearchBM}
                                    />
                                </div>
                                <div className={Styles.bmListWrapper}>
                                    {filteredBMList?.map((value, index) => (
                                        <div
                                            className={`${Styles.bmListBox} ${Styles.stackedProfile} ${filteredBMList?.length - 1 !== index ? Styles.notLastItem : ""}`}
                                            key={`bm_${index}`}
                                            onClick={() => {
                                              setSelectedBM(value?.employeeId)
                                              setEditBM(false)
                                              setSearchBM("")
                                            }}>
                                            <img
                                                style={{ width: '26px' }}
                                                src={
                                                    !value?.profilePicture
                                                        ? Images?.AVA_DEFAULT
                                                        : value?.profilePicture?.includes('http')
                                                        ? value?.profilePicture
                                                        : fileBaseUrl + value?.profilePicture
                                                }
                                                alt={value?.name}
                                                onError={handleImageError}
                                            />
                                            <span>{value?.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={Styles.buttons}>
                    <div className={Styles.secondaryButton} onClick={onClickBack}>
                        <div className={Styles.text}>Kembali</div>
                    </div>
                    <div className={Styles.primaryButton} onClick={submit}>
                        <div className={Styles.text}>Kirim</div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default RequestApprovalExtendContractBmModal;
