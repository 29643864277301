import Styles from "./style.module.scss";
import ICON365 from "../../../assets/Images/kerja365-logo-bs.png";
import ICON365ALT from "../../../assets/Images//kerja365-logo-bs2.png";
import WA_ICON from "../../../assets/Images/whatsapp-logo.png";
import { useState, useEffect, useMemo } from "react";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "@Theme/Images";
import { fileBaseUrl } from "@Config/api";
import BSProfilePopup from "@Molecule/_popup/BSProfilePopup";

const BSNavbar = ({ children, ...props }) => {
  const currentMenu = sessionStorage.getItem("selectedMenu");
  const [onHover, setOnHover] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(+currentMenu || 1);
  const [submenu, setSubmenu] = useState(false);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const username = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const profilePictureSource = localStorage.getItem("profilePicture");

  const profilePicture = useMemo(() => {
    if (profilePictureSource && profilePictureSource !== "null") {
      if (profilePictureSource?.includes("http")) {
        return profilePictureSource?.replace(
          "https://myworkspace",
          "https://api1.myworkspace"
        );
      } else {
        return fileBaseUrl + profilePictureSource;
      }
    } else {
      return Images.AVA_DEFAULT;
    }
  }, [profilePictureSource]);

  const [showDropdown, setShowDropdown] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!onHover) {
      setSubmenu(false);
    }
    if (!burgerOpen) {
      setSubmenu(false);
    }
  }, [onHover, burgerOpen]);

  const handleBack = () => {
    if (pathname?.includes("/candidates/")) {
      navigate("/candidates", { replace: true });
    }
    if (pathname?.includes("/data/contracts/")) {
      navigate("/data/contracts", { replace: true });
    }
    if (pathname?.includes("/pending-contracts/")) {
      navigate("/data/pending-contracts", { replace: true });
    }
  };

  const activeMenu = useMemo(() => {
    if (pathname === "/") {
      return 1;
    } else if (pathname?.toLowerCase()?.includes("candidates")) {
      return 2;
    } else if (pathname?.toLowerCase()?.includes("/contracts")) {
      return 3;
    } else if (pathname?.toLowerCase()?.includes("pending")) {
      return 4;
    } else if (pathname?.toLowerCase()?.includes("/complete")) {
      return 5;
    }
  }, [pathname]);

  useEffect(() => {
    sessionStorage.setItem("selectedMenu", activeMenu);
    setSelectedMenu(activeMenu);
  }, [activeMenu]);

  // useEffect(() => {
  //   sessionStorage.setItem("selectedMenu", selectedMenu);
  // }, [selectedMenu]);

  const menuTemplate = useMemo(() => {
    return [
      {
        id: 1,
        title: "Dashboard",
      },
      {
        id: 2,
        title: "Kandidat",
      },
      {
        id: 3,
        title: "Perubahan Kontrak",
      },
      {
        id: 4,
        title: "Proses Perubahan Kontrak",
      },
      {
        id: 5,
        title: "Selesai",
      },
    ];
  }, []);

  const isNavigated = useMemo(() => {
    switch (pathname) {
      case "/":
        return false;
      case "/candidates":
        return false;
      case "/data/contracts":
        return false;
      case "/data/pending-contracts":
        return false;
      case "/data/complete-contracts":
        return false;
      default:
        return true;
    }
  }, [pathname]);

  return (
    <div className={Styles.container}>
      <div
        className={Styles.sidebar}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <div className={Styles.top}>
          {onHover ? (
            <div className={Styles.iconWrapper}>
              <img src={ICON365ALT} alt="" height={"34px"} />
            </div>
          ) : (
            <div className={Styles.iconWrapper}>
              <img src={ICON365} alt="" height={"34px"} />
            </div>
          )}
          <div className={Styles.navbar}>
            <div
              className={
                selectedMenu !== 1
                  ? Styles.menuWrapper
                  : Styles.selectedMenuWrapper
              }
              onClick={() => {
                setSelectedMenu(1);
                navigate("/");
              }}
            >
              <div className={Styles.button}>
                <Icon icon="squares" size="20px" className={Styles.icon} />
                {onHover && <div className={Styles.text}>Dashboard</div>}
              </div>
            </div>
            <div
              className={
                selectedMenu !== 2
                  ? Styles.menuWrapper
                  : Styles.selectedMenuWrapper
              }
              onClick={() => {
                setSelectedMenu(2);
                navigate("candidates");
              }}
            >
              <div className={Styles.button}>
                <Icon icon="user-group" size="20px" className={Styles.icon} />
                {onHover && <div className={Styles.text}>Kandidat</div>}
              </div>
            </div>
            {/* <div
              className={
                selectedMenu !== 3
                  ? Styles.menuWrapper
                  : Styles.selectedMenuWrapper
              }
              onClick={() => {
                setSelectedMenu(3);
                navigate("data/contracts");
              }}
              // style={{display: 'none'}}
            >
              <div className={Styles.button}>
                <Icon icon="rectangle-stack" size="20px" className={Styles.icon} />
                {onHover && <div className={Styles.text}>Data Kontrak</div>}
              </div>
            </div> */}
            <div
              className={
                (selectedMenu === 3 && !onHover) ||
                (selectedMenu === 4 && !onHover) ||
                (selectedMenu === 5 && !onHover)
                  ? Styles.selectedMenuWrapper
                  : Styles.menuWrapper
              }
              onClick={() => setSubmenu(!submenu)}
            >
              <div className={Styles.button}>
                <Icon
                  icon="rectangle-stack"
                  size="20px"
                  className={Styles.icon}
                />
                {onHover && <div className={Styles.text}>Perubahan Kontrak</div>}
              </div>
              {onHover && (
                <Icon
                  icon={submenu ? "arrow-up" : "arrow-down"}
                  size="20"
                  className={Styles.arrow}
                />
              )}
            </div>
            {submenu && onHover && (
              <div className={Styles.submenuWrapper}>
                <div className={Styles.submenu}>
                  <div className={Styles.curveWrapper}>
                    <div className={Styles.curve}></div>
                    <div className={Styles.straight}></div>
                  </div>
                  <div className={Styles.button}>
                    <div
                      className={
                        selectedMenu !== 3
                          ? Styles.menuWrapper
                          : Styles.selectedMenuWrapper
                      }
                      onClick={() => {
                        setSelectedMenu(3);
                        navigate("data/contracts");
                      }}
                    >
                      <div className={Styles.text}>Cari Karyawan</div>
                    </div>
                  </div>
                </div>
                <div className={Styles.submenu}>
                  <div className={Styles.curveWrapper}>
                    <div className={Styles.curve}></div>
                    <div className={Styles.straight}></div>
                  </div>
                  <div className={Styles.button}>
                    <div
                      className={
                        selectedMenu !== 4
                          ? Styles.menuWrapper
                          : Styles.selectedMenuWrapper
                      }
                      onClick={() => {
                        setSelectedMenu(4);
                        navigate("data/pending-contracts");
                      }}
                    >
                      <div className={Styles.text}>Proses</div>
                    </div>
                  </div>
                </div>
                <div className={Styles.submenu}>
                  <div className={Styles.curveWrapper}>
                    <div className={Styles.curve}></div>
                    <div
                      className={Styles.straight}
                      style={{ height: 5 }}
                    ></div>
                  </div>
                  <div className={Styles.button}>
                    <div
                      className={
                        selectedMenu !== 5
                          ? Styles.menuWrapper
                          : Styles.selectedMenuWrapper
                      }
                      onClick={() => {
                        setSelectedMenu(5);
                        navigate("data/complete-contracts");
                      }}
                    >
                      <div className={Styles.text}>Selesai</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={Styles.bottom}>
          <div className={Styles.helpdesk}>
            <div className={Styles.icon}>
              <img src={WA_ICON} height={21.33} width={21.33} alt="" />
            </div>
            {onHover && (
              <div className={Styles.helpdeskButtonWrapper}>
                <div className={Styles.information}>
                  <div className={Styles.title}>Help Desk</div>
                  <div className={Styles.text}>
                    Butuh bantuan? kami siap membantu anda
                  </div>
                </div>
                <div className={Styles.pressable}>
                  <div className={Styles.label}>Mulai Chat</div>
                  <Icon
                    icon="arrow-right-stick"
                    size="20"
                    className={Styles.arrow}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.header}>
          <div className={Styles.routeNameWrapper}>
            <div className={Styles.routeNameTitle}>
              {menuTemplate?.find((obj) => obj?.id === selectedMenu)?.title}
            </div>
            {isNavigated && (
              <div className={Styles.navigationTextWrapper}>
                <div onClick={handleBack} className={Styles.linkText}>
                  {menuTemplate?.find((obj) => obj?.id === selectedMenu)?.title}
                </div>
                <Icon
                  icon="arrow-right"
                  size="20"
                  className={Styles.arrowIcon}
                />
                <div className={Styles.text}>
                  {pathname?.includes("/candidates/") ? "Verifikasi Data" : ""}
                  {pathname?.includes("/data/contracts/")
                    ? "Verifikasi Data"
                    : ""}
                  {pathname?.includes("/pending-contracts/")
                    ? "Detail Kontrak"
                    : ""}
                </div>
              </div>
            )}
          </div>
          <div
            className={Styles.iconWrapper}
            onClick={() => setBurgerOpen(!burgerOpen)}
          >
            <Icon
              icon={burgerOpen ? "cross" : "bars-3-bottom-left"}
              size="24"
              color="#000"
            />
          </div>
          <div
            className={Styles.profileWrapper}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className={Styles.profilePicture}>
              <img src={profilePicture} alt="" />
            </div>
            <div className={Styles.userInformation}>
              <div className={Styles.username}>{username}</div>
              <div className={Styles.jobTitle}>{role}</div>
            </div>
            <div className={Styles.iconWrapper}>
              <Icon
                icon={showDropdown ? "arrow-up" : "arrow-down"}
                size="20"
                className={Styles.icon}
              />
            </div>
            {showDropdown && (
              <div className={Styles.dropdownWrapper}>
                <BSProfilePopup
                  profilePicture={profilePicture}
                  username={username}
                  role={role}
                />
                {/* <div>hahahha</div> */}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.body}>{children}</div>
        <div
          className={
            burgerOpen
              ? Styles.menuModalWrapper
              : Styles.inactiveMenuModalWrapper
          }
        >
          <div className={Styles.menuModal}>
            <>
              <div
                className={Styles.navigation}
                onClick={() => {
                  setSelectedMenu(1);
                  navigate("/");
                  setBurgerOpen(false);
                }}
              >
                <div
                  className={
                    selectedMenu === 1 ? Styles.selectedButton : Styles.button
                  }
                >
                  <Icon icon="squares" size="20" className={Styles.icon} />
                  <div className={Styles.text}>Dashboard</div>
                </div>
              </div>
              <div
                className={Styles.navigation}
                onClick={() => {
                  setSelectedMenu(2);
                  navigate("candidates");
                  setBurgerOpen(false);
                }}
              >
                <div
                  className={
                    selectedMenu === 2 ? Styles.selectedButton : Styles.button
                  }
                >
                  <Icon icon="user-group" size="20" className={Styles.icon} />
                  <div className={Styles.text}>Kandidat</div>
                </div>
              </div>
              <div
                className={Styles.navigation}
                onClick={() => {
                  // setSubmenu(!submenu)
                  setSelectedMenu(3);
                  navigate("data/contracts");
                  setBurgerOpen(false);
                }}
              >
                <div
                  className={
                    (selectedMenu === 3 && !submenu) ||
                    (selectedMenu === 4 && !submenu) ||
                    (selectedMenu === 5 && !submenu)
                      ? Styles.selectedButton
                      : Styles.button
                  }
                >
                  <Icon
                    icon="rectangle-stack"
                    size="20"
                    className={Styles.icon}
                  />
                  <div className={Styles.text}>Data</div>
                </div>
                {/* <div className={Styles.arrowWrapper}>
                  <Icon
                    icon={submenu ? "arrow-up" : "arrow-down"}
                    size="20"
                    className={Styles.arrow}
                  />
                </div> */}
              </div>
            </>
            {/* <div
              className={`${submenu ? Styles.activeSubNavigation : Styles.subNavigation
                } ${selectedMenu === 3 ? Styles.selectedSubNavigation : null}`}
              onClick={() => {
                setSelectedMenu(3);
                navigate("data/contracts");
                setBurgerOpen(false);
              }}
            >
              {burgerOpen && <div className={Styles.text}>Contract</div>}
            </div>
            <div
              className={`${submenu ? Styles.activeSubNavigation : Styles.subNavigation
                } ${selectedMenu === 4 ? Styles.selectedSubNavigation : null}`}
              onClick={() => {
                setSelectedMenu(4);
                navigate("data/pending-contracts");
                setBurgerOpen(false);
              }}
            >
              {burgerOpen && (
                <div className={Styles.text}>Pending Contract</div>
              )}
            </div>
            <div
              className={`${submenu ? Styles.activeSubNavigation : Styles.subNavigation
                } ${selectedMenu === 5 ? Styles.selectedSubNavigation : null}`}
              onClick={() => {
                setSelectedMenu(5);
                navigate("data/complete-contracts");
                setBurgerOpen(false);
              }}
            >
              {burgerOpen && (
                <div className={Styles.text}>Complete Contract</div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BSNavbar;
